/**
 * @file components/Modals/publishSurveyModal.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { unpublish_survey_delete } from "../../actions/surveyAction";
import { PAGES } from "../../constants/pages";
import { RESPONSE_CODE } from "../../constants/errors";

class UnpublishSurveyModal extends React.Component {
  constructor() {
    super();
    this.state = {
      buttonEnabled: true,
    };
  }

  unpublishTheSurvey() {
    this.setState({ buttonEnabled: false });
    return this.props
      .unpublish_survey_delete(this.props.survey.survey_id)
      .then((response) => {
        this.setState({ buttonEnabled: true });
        this.closeModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        }
      });
  }

  closeModal() {
    this.props.hideModal();
  }

  render() {
    const surveyTitle =
      this.props.survey != null ? this.props.survey.title : "";
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="contained-modal-title-vcenter">
            UNPUBLISH SURVEY
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to unpublish the Survey <b>{surveyTitle}</b>
          </p>
          <p className="pt-2">Do you wish to continue?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!this.state.buttonEnabled}
            variant="secondary"
            onClick={() => this.closeModal()}
          >
            Cancel
          </Button>
          <Button
            disabled={!this.state.buttonEnabled}
            variant="primary"
            onClick={() => this.unpublishTheSurvey()}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    survey: ownProps.survey,
  };
};

export default withRouter(
  connect(mapStateToProps, { unpublish_survey_delete })(UnpublishSurveyModal),
);
