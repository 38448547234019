import { get } from "lodash/fp";
import { SPIT_KIT_REDUCER_NAMESPACE } from "./Constants";

export const selectSpitKitListPagination = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitListPagination`,
);

export const selectSpitKitCurrentOrder = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.currentSpitKit`,
);

export const selectSpitKitListFilters = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitListFilters`,
);

export const selectSpitKitList = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitList`,
);

export const selectSpitKitListLoading = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitListLoading`,
);

export const selectSpitKitSummary = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitSummary`,
);

export const selectSpitKitSummaryIsLoading = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitSummaryIsLoading`,
);

export const selectSpitKitErrors = get(`${SPIT_KIT_REDUCER_NAMESPACE}.errors`);

export const selectSpitKitFulfilledList = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitFulfilledList`,
);

export const selectSpitKitListIsFulfillingNewOrders = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitListIsFulfillingNewOrders`,
);

export const selectSpitKitImportResult = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitImportResult`,
);

export const selectSpitKitSelectedOrders = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitSelectedOrders`,
);

export const selectSpitKitBulkOperations = get(
  `${SPIT_KIT_REDUCER_NAMESPACE}.spitKitBulkOperations`,
);
