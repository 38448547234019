import React from "react";

export type NoEntriesRowProps = {
  cellClassName?: string;
  colSpan?: number;
  children?: React.ReactNode;
};

function NoEntriesRow({
  cellClassName,
  colSpan = 1,
  children,
}: NoEntriesRowProps) {
  return (
    <tr>
      <td colSpan={colSpan} className={cellClassName}>
        {children}
      </td>
    </tr>
  );
}

export default NoEntriesRow;
