import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_login = {
  get_login(success, fail) {
    return utils_api.auth_get(API_URL.LOGIN_GET, success, fail);
  },
  login(success, fail, data) {
    return utils_api.unauth_post(API_URL.LOGIN_POST, data, success, fail);
  },
  login_mfa_otp_request(success, payload, fail) {
    return utils_api.auth_post(
      API_URL.LOGIN_MFA_OTP_REQUEST,
      payload,
      success,
      fail,
    );
  },
  login_mfa_otp_validation(success, fail, otpId, otp) {
    const data = { otp_id: otpId, token: otp };
    return utils_api.auth_post(
      API_URL.LOGIN_MFA_OTP_VALIDATION,
      data,
      success,
      fail,
    );
  },
};
