import React from "react";
import { BadgeProps } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { RGCStatus } from "src/store/SpitKit/Types";

type RGCStatusDisplay = { text: string } & Pick<BadgeProps, "variant">;

const statusDisplays: Record<RGCStatus, RGCStatusDisplay> = {
  ordered: { variant: "dark", text: "Ordered" },
  pending: { variant: "secondary", text: "Pending" },
  in_fulfillment: { variant: "primary", text: "In Fulfillment" },
  shipped: { variant: "success", text: "Shipped" },
  error: { variant: "danger", text: "Error" },
  delivered: { variant: "success", text: "Delivered" },
  collected: { variant: "success", text: "Collected" },
};

// These are the props directly used when returning the component: <SpitKitStatus prop1={} prop2={} />
export type SpitKitStatusComponentProps = {
  status: RGCStatus;
};

export type SpitKitStatusProps = SpitKitStatusComponentProps;

function SpitKitStatus({ status }: SpitKitStatusProps) {
  const defaultStatusDisplay: RGCStatusDisplay = {
    variant: "secondary",
    text: "",
  };
  let statusDisplay: RGCStatusDisplay;
  if (!statusDisplays[status]) {
    console.log('Unknown order status "%s"', status);
    statusDisplay = defaultStatusDisplay;
  } else {
    statusDisplay = statusDisplays[status];
  }
  const { variant, text } = statusDisplay;

  return <Badge variant={variant}>{text}</Badge>;
}

export default SpitKitStatus;
