export const PAGES = {
  LOGIN: "/",
  PROFILE: "/profile",
  LOGOUT: "/logout",
  MFA_OTP: "/mfa-otp",

  FIRST_TIME_SET_PASSWORD: "/set-password",
  FIRST_TIME_SET_PASSWORD_COMPLETE: "/set-password-complete",
  LINK_EXPIRED: "/link-expired",

  RESET_PASSWORD_SEND_EMAIL: "/password-reset",
  RESET_PASSWORD_SEND_EMAIL_COMPLETE: "/password-reset/email-sent",
  RESET_PASSWORD_EXPIRED: "/password-reset/expired",
  RESET_PASSWORD_SET_NEW: "/password-reset/set-new",
  RESET_PASSWORD_COMPLETE: "/password-reset/confirmed",

  DASHBOARD: "/dashboard",

  SURVEYS: "/surveys",
  SURVEY_DETAIL: "/surveys/detail",
  SURVEY_PREVIEW: "/surveys/preview",

  APP_USERS: "/users",
  APP_USERS_DETAIL: "/users/detail",

  ANCESTRY: "/ancestry",

  TERMS_AND_CONDITIONS: "/terms",
  PRIVACY_POLICY: "/privacy",

  SYSTEM_CONFIG: "/system/config",
  SYSTEM_REPORTS: "/system/reports",

  POLLS: "/polls",

  SPIT_KIT: "/spit-kit",
  SPIT_KIT_EDIT: "/spit-kit/edit",
  SPIT_KIT_CREATE: "/spit-kit/new",
};
