import React, { useState } from "react";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import GenericModal from "src/components/global/GenericModal/GenericModal";

export type OrderAction = "delete" | "cancel";

export type OrderActionStatus = "started" | "completed" | undefined;

// These are the props directly used when returning the component: <SpitKitActionsModal prop1={} prop2={} />
export type SpitKitActionsModalComponentProps = {
  orderToModify?: string;
  action?: OrderAction;
  onCancel?: () => void;
  onActionStatus?: (status: OrderActionStatus) => void;
};

export type SpitKitActionsModalProps = SpitKitActionsModalComponentProps;

function SpitKitActionsModal({
  orderToModify,
  action,
  onCancel = () => {},
  onActionStatus = () => {},
}: SpitKitActionsModalProps) {
  const [orderActionStatus, setOrderActionStatus] =
    useState<OrderActionStatus>();

  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  const changeStatus = (status: OrderActionStatus) => {
    setOrderActionStatus(status);
    onActionStatus(status);
  };

  const handlePerformAction = async () => {
    if (orderToModify === undefined) {
      return;
    }
    changeStatus("started");

    changeStatus("completed");

    changeStatus(undefined);
  };

  return (
    <GenericModal
      title={<span className="text-capitalize">{action} Order</span>}
      show={action !== undefined}
      proceeding={orderActionStatus === "started"}
      onCancel={onCancel}
      onContinue={handlePerformAction}
    >
      <p>
        Are you sure you would like to{" "}
        <strong className="text-capitalize">{action}</strong> this order?
      </p>
      <p>
        <strong>This action cannot be undone</strong>
      </p>
    </GenericModal>
  );
}

export default SpitKitActionsModal;
