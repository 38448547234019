/**
 * @file components/login/linkexpired.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
  CONTENT_ERROR_DISPLAY,
} from "../../constants/errors";

import { get_terms } from "../../actions/contentAction";

class TermsAndConditions extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      content: null,
    };
  }

  componentDidMount() {
    this.getContent();
  }

  handlerError(error) {
    this.setState({ errors: { system: error } });
  }

  getContent() {
    return this.props
      .get_terms()
      .then((response) => {
        this.setState({ content: response });
      })
      .catch((error) => {
        if (error.response.status === RESPONSE_CODE["404_notfound"]) {
          this.handlerError(CONTENT_ERROR_DISPLAY.NO_DATA_FOUND);
        } else {
          this.handlerError(UNEXPECTED_ERROR);
        }
      });
  }

  render() {
    return (
      <section className="legal-content-container container">
        <div
          className=" mb-3 text-break"
          dangerouslySetInnerHTML={{ __html: this.state.content }}
        ></div>

        <div>
          {this.state.errors.system ? (
            <div className="mb-3 text-center text-danger">
              {this.state.errors.system}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, { get_terms })(TermsAndConditions),
);
