import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_reset_password = {
  send_reset_password_email(success, fail, data) {
    return utils_api.unauth_post(
      API_URL.REQUEST_PASSWORD_RESET_POST,
      data,
      success,
      fail,
    );
  },
  update_reset_password(success, fail, password) {
    const data = { new_password: password };
    return utils_api.auth_put(API_URL.RESET_PASSWORD_PUT, data, success, fail);
  },
};
