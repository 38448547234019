import { useEffect, useState } from "react";
// No types for react-redux unless we fix DNA kit component first
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faPoll, faSitemap } from "@fortawesome/free-solid-svg-icons";

import { dash_kpi } from "src/actions/reportAction";
import { PAGES } from "src/constants/pages";
import { FEATURE } from "src/constants/features";
import { RESPONSE_CODE } from "src/constants/errors";

import styles from "./dashboard-metrics.module.css";

function DashboardCard({ title, count, icon, color }) {
  const borderColorClass =
    {
      green: "border-left-success",
      yellow: "border-left-warning",
      red: "border-left-danger",
      blue: "border-left-info",
    }[color] || "";

  const textColorClass =
    {
      green: "text-success",
      yellow: "text-warning",
      red: "text-danger",
      blue: "text-info",
    }[color] || "";

  return (
    <div className={`card ${borderColorClass} h-100 py-2`}>
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <div
              className={`text-xs font-weight-bold ${textColorClass} text-uppercase mb-1 ${styles.title}`}
            >
              {title}
            </div>
            <div
              className={`h1 mb-0 font-weight-bold text-muted ${styles.count}`}
            >
              {count}
            </div>
          </div>
          <div className="col-auto  fa-2x  text-gray-500">{icon}</div>
        </div>
      </div>
    </div>
  );
}

export default function DashboardMetrics() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [metrics, setMetrics] = useState(null);

  // Fetch metrics on first render
  useEffect(() => {
    dispatch(dash_kpi())
      .then((response) => {
        if (response.metrics) {
          setMetrics({
            totalAppUsers: response.metrics.total_users,
            appUsers: response.metrics.users,
            surveys: response.metrics.surveys,
            orders: response.metrics.orders,
          });
        } else {
          console.error("Error: no metrics property in response");
        }
      })
      .catch((error) => {
        if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
          history.push(PAGES.LOGIN);
        } else {
          console.error("Error fetching metrics");
        }
      });
  }, [dispatch, history]);

  return (
    <div className="container pt-4">
      <div className="row pt-4">
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Participants"
            count={metrics ? metrics.totalAppUsers || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>

        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="green"
            title="Active participants"
            count={metrics?.appUsers ? metrics.appUsers.active || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Users pending"
            count={metrics?.appUsers ? metrics.appUsers.pending || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Users in enrollment"
            count={metrics?.appUsers ? metrics.appUsers.enrollment || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Users suspended"
            count={metrics?.appUsers ? metrics.appUsers.suspend || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Users hard delete requested"
            count={
              metrics?.appUsers ? metrics.appUsers.hard_delete_request || 0 : ""
            }
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Users hard deleted"
            count={metrics?.appUsers ? metrics.appUsers.hard_delete || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="red"
            title="Users kba failed"
            count={
              metrics?.appUsers ? metrics.appUsers.kba_not_verified || 0 : ""
            }
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="red"
            title="Users locked out"
            count={metrics?.appUsers ? metrics.appUsers.lockout || 0 : ""}
            icon={<FontAwesomeIcon icon={faUsers} />}
          />
        </div>
      </div>

      {
        // TODO: ancestry metric looks not implemented well in
        // the backend
        FEATURE.ANCESTRY ? (
          <div className="row pt-4">
            <div className="col-xl-3 col-md-3 mb-3">
              <DashboardCard
                color="red"
                title="Ancestry Not Available"
                count={0}
                icon={<FontAwesomeIcon icon={faSitemap} />}
              />
            </div>
          </div>
        ) : null
      }

      {FEATURE.SURVEY ? (
        <div className="row pt-4">
          <div className="col-xl-3 col-md-3 mb-3">
            <DashboardCard
              color="green"
              title="Surveys Published"
              count={metrics ? metrics.surveys.publish || 0 : ""}
              icon={<FontAwesomeIcon icon={faPoll} />}
            />
          </div>
          <div className="col-xl-3 col-md-3 mb-3">
            <DashboardCard
              color="blue"
              title="Surveys Unpublished"
              count={metrics ? metrics.surveys.unpublish || 0 : ""}
              icon={<FontAwesomeIcon icon={faPoll} />}
            />
          </div>
        </div>
      ) : null}

      <div className="row pt-4">
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Kit requests ordered"
            count={metrics?.orders ? metrics.orders.ordered || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Kit requests pending"
            count={metrics?.orders ? metrics.orders.pending || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Kit requests shipped"
            count={metrics?.orders ? metrics.orders.shipped || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Kit requests delivered"
            count={metrics?.orders ? metrics.orders.delivered || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="blue"
            title="Kit requests in fulfillment"
            count={metrics?.orders ? metrics.orders.in_fulfillment || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
        <div className="col-xl-3 col-md-3 mb-3">
          <DashboardCard
            color="red"
            title="Kit requests error"
            count={metrics?.orders ? metrics.orders.error || 0 : ""}
            icon={<FontAwesomeIcon icon={faPoll} />}
          />
        </div>
      </div>
    </div>
  );
}
