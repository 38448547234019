import { CsvExportSettings } from "src/services/CsvService";
import { SpitKitOrderEntry } from "src/store/SpitKit/Types";

export const SpitKitCsvExportSettings: CsvExportSettings<SpitKitOrderEntry> = {
  columns: [
    { title: "order_id", extractor: "order_id" },
    { title: "barcode", extractor: "serial_id" },
    { title: "sku_id", extractor: "sku_id" },
    { title: "tracking_number", extractor: "tracking_number" },
    { title: "status", extractor: "status" },
    { title: "First Name", extractor: "first_name" },
    { title: "Last Name", extractor: "last_name" },
    { title: "Email", extractor: "email" },
    { title: "Phone", extractor: "order_phone" },
    { title: "Fist Line Address", extractor: "address_line1" },
    { title: "Second Line Address", extractor: "address_line2" },
    { title: "City", extractor: "city" },
    { title: "State", extractor: "state" },
    { title: "Zip", extractor: "zip" },
  ],
};
