import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_survey } from "../api/api_survey.js";

export function get_survey_list_get(pageNum) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEYS_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_survey.get_surveys_list(success, fail, pageNum);
  };
}

export function publish_survey_post(surveyId) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEY_PUBLISH,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_survey.post_publish_survey(success, fail, surveyId);
  };
}

export function group_survey_post(surveyId, group) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEY_PUBLISH,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);

    return api_survey.post_group_survey(success, fail, surveyId, group);
  };
}

export function unpublish_survey_delete(surveyId) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEY_DELETE,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_survey.delete_unpublish_survey(success, fail, surveyId);
  };
}

export function download_survey_single_get(surveyId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REDUX_ACTION.SURVEY_DOWNLOAD,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) => {};
    return api_survey.get_download_survey_single(success, fail, surveyId);
  };
}

export function survey_preview(surveyId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REDUX_ACTION.SURVEY_DOWNLOAD,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) => {};
    return api_survey.get_survey_preview(success, fail, surveyId);
  };
}
export function export_surveys_get() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REDUX_ACTION.SURVEYS_EXPORT,
        data: response.data,
      });

      return response.data;
    };
    const fail = (error) => {};
    return api_survey.get_export_surveys(success, fail);
  };
}

export function export_surveys_status_get(exportToken) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REDUX_ACTION.SURVEYS_EXPORT_STATUS,
        data: response.data,
      });

      return response.data;
    };
    const fail = (error) => {};
    return api_survey.get_export_surveys_status(success, fail, exportToken);
  };
}

export function ls_import_surveys_post() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REDUX_ACTION.SURVEYS_EXPORT_STATUS,
        data: response.data,
      });

      return response.data;
    };
    const fail = (error) => {};
    return api_survey.post_import_ls_survey(success, fail);
  };
}

export function upload_surveys_post(data) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEYS_EXPORT_STATUS,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_survey.post_import_survey(success, fail, data);
  };
}
