import Joi, { ObjectSchema } from "joi";

export interface UserLookupInterface<TEmail = string> {
  email: TEmail;
}

export type UserLookupValidationJoi = UserLookupInterface<Joi.StringSchema>;

export const USER_LOOKUP_VALIDATION: ObjectSchema<UserLookupValidationJoi> =
  Joi.object({
    email: Joi.string()
      .required()
      .email({ minDomainSegments: 2, tlds: false })
      .label("Email"),
  });
