import { combineReducers } from "redux";
import loginUser from "./loginReducer";
import spinner from "./spinnerReducer";
import surveys from "./surveysReducer";
import survey from "./surveyReducer";
import { SPIT_KIT_REDUCER_NAMESPACE } from "src/store/SpitKit/Constants";
import spitKitReducer from "src/store/SpitKit/SpitKitReducer";

export default combineReducers({
  loginUser,
  spinner,
  surveys,
  survey,
  [SPIT_KIT_REDUCER_NAMESPACE]: spitKitReducer,
});
