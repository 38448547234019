import { ANCESTRY } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_ancestry = {
  upload_ancestry(success, fail, data) {
    return utils_api.auth_post_raw(ANCESTRY.UPLOAD, data, success, fail);
  },

  get_ancestry_logs(success, fail) {
    return utils_api.auth_get(ANCESTRY.ANCESTRY_LOG, success, fail);
  },

  get_ancestry_upload_status(success, fail, uploadToken) {
    return utils_api.auth_get(
      ANCESTRY.ANCESTRY_GET_UPLOAD_STATUS + "/" + uploadToken,
      success,
      fail,
    );
  },
};
