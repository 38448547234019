import { APP_USERS_API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_app_user = {
  post_app_user_list(success, fail, data) {
    return utils_api.auth_post(
      APP_USERS_API_URL.USERS_POST,
      data,
      success,
      fail,
    );
  },

  resend_app_invite(success, fail, email) {
    const data = { email: email };
    return utils_api.auth_post(
      APP_USERS_API_URL.APP_USER_REINVITE,
      data,
      success,
      fail,
    );
  },

  suspend_app_user(success, fail, userId) {
    const data = { status: "suspend" };
    return utils_api.auth_put(
      APP_USERS_API_URL.APP_USER_PUT + "/" + userId,
      data,
      success,
      fail,
    );
  },

  activate_app_user(success, fail, userId) {
    const data = { status: "active" };
    return utils_api.auth_put(
      APP_USERS_API_URL.APP_USER_PUT + "/" + userId,
      data,
      success,
      fail,
    );
  },
};
