import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_app_user } from "../api/api_app_user";

export function post_app_user_list(data) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.APP_USERS_POST,
        data: response.data.data,
      });

      return response.data.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_app_user.post_app_user_list(success, fail, data);
  };
}

export function resend_app_invite(app_user_id) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_app_user.resend_app_invite(success, fail, app_user_id);
  };
}

export function suspend_app_user(app_user_id) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);

    return api_app_user.suspend_app_user(success, fail, app_user_id);
  };
}

export function activate_app_user(app_user_id) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);

    return api_app_user.activate_app_user(success, fail, app_user_id);
  };
}

export function action_app_user(app_user_id, targetAction) {
  if (targetAction === "suspend") {
    return suspend_app_user(app_user_id);
  } else if (targetAction === "activate") {
    return activate_app_user(app_user_id);
  } else {
    return null;
  }
}
