/**
 * @file components/login/header.js
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { logout_get } from "../../actions/logoutAction";
import { PAGES } from "../../constants/pages";
import { UNEXPECTED_ERROR } from "../../constants/errors";

class Logout extends React.Component {
  constructor() {
    super();

    this.handleChange = this.handleLogout.bind(this);
  }

  handleLogout(e) {
    e.preventDefault();
    this.props
      .logout_get()
      .then((response) => this.props.history.push(PAGES.LOGIN))
      .catch((error) =>
        this.setState({ errors: { system: UNEXPECTED_ERROR } }),
      );
  }

  render() {
    return (
      <Button className="btn-sm" onClick={(e) => this.handleLogout(e)}>
        Logout
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(connect(mapStateToProps, { logout_get })(Logout));
