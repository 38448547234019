/**
 * @file components/login/consent.js
 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { UNEXPECTED_ERROR, RESPONSE_CODE } from "../../constants/errors";
import { PAGES } from "../../constants/pages";
import { get_logs, get_healthcheck } from "../../actions/reportAction";

import moment from "moment";

import { ls_import_surveys_post } from "../../actions/surveyAction";
import { import_poll } from "../../actions/pollAction";

class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      anlogs: null,

      currentPage: 1,
      modalIsOpen: false,
      modalError: false,
      param: "survey_publish",
      reportName: "Survey Publish",
      healthcheck: null,
    };
  }

  componentDidMount() {
    this.getLogs(this.state.param);
    this.getHealthCheck();
  }

  getLogs(param) {
    return this.props
      .get_logs(param)
      .then((response) => {
        this.setState({
          anlogs: response,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        } else {
          this.handlerError(UNEXPECTED_ERROR);
        }
      });
  }

  getHealthCheck() {
    return this.props
      .get_healthcheck()
      .then((response) => {
        this.setState({
          healthcheck: response,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        } else {
          this.handlerError(UNEXPECTED_ERROR);
        }
      });
  }

  handlerError(error) {
    this.setState({ errors: { system: error } });
  }

  momentFormat(sourceDate) {
    return sourceDate
      ? moment(sourceDate).format("MMMM D YYYY  h:mm:ss a")
      : "NA";
  }

  lastDayOfImport() {
    const { anlogs } = this.state;
    const [recentLogs] = anlogs || [];
    return this.momentFormat(recentLogs?.action_date);
  }

  renderHealth() {
    const { healthcheck: data } = this.state;

    if (!data) {
      return null;
    }

    return (
      <div className="container-fluid">
        <ul className="list-group list-group-flush mt-3 mb-3">
          <li className="list-group-item list-group-item-primary">
            System Healthcheck {moment().format("MMMM Do YYYY, h:mm:ss a")}
          </li>
          {data.map((log, key) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center text-uppercase"
              key={key}
            >
              <small>{log}</small>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderLogs() {
    const { anlogs: data } = this.state;

    if (!data) {
      return null;
    }

    return (
      <div className="container-fluid">
        <table className="table" width="100%">
          <thead>
            <tr>
              <th width="20%">Date</th>
              <th width="20%">Action Type</th>
              <th width="60%">Action</th>
            </tr>
          </thead>

          <tbody>
            {data.length > 0 ? (
              data.map((log, key) => (
                <tr key={key}>
                  <td className="text-gray-800 mb-0">
                    {" "}
                    <small>{this.momentFormat(log.action_date)}</small>
                  </td>
                  <td className="p-0">
                    <small>{log.action_type}</small>
                  </td>
                  <td className="p-0">
                    <small>{log.description}</small>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-muted">
                  No entries found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  setParam(q, name) {
    this.setState({
      param: q,
      reportName: name,
      anlogs: null,
    });

    this.getLogs(q);
  }

  render() {
    return (
      <section className="surveys container">
        <div className="mt-3 mb-3 ">
          <div className="mb-3 text-muted mb-0 text-gray-800">
            {" "}
            <span className="h3">Reports</span> &nbsp; &nbsp;
            <p></p>
            {this.renderHealth()}
            <div className="container-fluid">
              {this.state.healthcheck ? null : (
                <div className="m-5 spinner-border justify-content-center">
                  {" "}
                  <span className="sr-only">Loading...</span>
                </div>
              )}

              <nav className="navbar navbar-light list-group-item-primary">
                Application Logs
              </nav>
              <Dropdown>
                <Dropdown.Toggle
                  variant="info"
                  id="dropdown-basic"
                  size="sm"
                  className="mt-3 mb-3"
                >
                  {this.state.reportName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      this.setParam("survey_publish", "Survey Publish")
                    }
                  >
                    Survey Publish
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setParam(
                        "surveys_import_limesurvey",
                        "Survey Import",
                      )
                    }
                  >
                    Survey Import
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setParam("quickpoll_import", "Quick Poll")
                    }
                  >
                    Quick Poll
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setParam("acestry_import_file", "Ancestry Import")
                    }
                  >
                    Ancestry Import
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setParam("schedulejob_run", "Schedule Job")
                    }
                  >
                    Schedule Job
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <p className="">
                {" "}
                <small>
                  {" "}
                  Displaying last 50 activities. Last activity was performed on{" "}
                  {this.lastDayOfImport()}.
                </small>
              </p>
            </div>
          </div>
          {this.renderLogs()}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    get_logs,
    get_healthcheck,
    ls_import_surveys_post,
    import_poll,
  })(Reports),
);
