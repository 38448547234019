import Axios, { Method } from "axios";
import { URL_ROOT_API } from "src/constants/api";
import { utils_user } from "src/utils/utils_user";

export type Result<TResult> = {
  data: TResult;
  success: boolean;
};

export type ErrorResult<TMsg = string> = {
  msg: TMsg;
  success: boolean;
};

export type ApiCall = {
  endpoint: string;
  auth?: boolean;
  method?: Method;
  data?: any;
  headers?: any;
  params?: Record<string, string>;
};

function processBody(body: any) {
  if (body instanceof FormData) {
    return body;
  }
  if (body !== undefined) {
    return JSON.stringify(body);
  }
  return body;
}

export async function api<TResult>({
  endpoint,
  auth = true,
  method = "GET",
  data: body,
  headers,
  params,
}: ApiCall): Promise<Result<TResult>> {
  const data = processBody(body);

  const result = await Axios.request<Result<TResult>>({
    baseURL: URL_ROOT_API,
    url: endpoint,
    method,
    params,
    headers: {
      ...headers,
      ...(auth ? { "x-api-key": utils_user.get_user_jwt() } : {}),
    },
    data,
  });

  return result.data;
}
