import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import DashboardMetrics from "./dashboard-metrics";

export default function Dashboard() {
  const loginUser = useSelector((state) => state.loginUser);

  return (
    <>
      <div className="container pt-4">
        <p className="mb-0 text-muted text-capitalize">
          Welcome {loginUser.first_name}
        </p>
      </div>
      <DashboardMetrics />
      <div className="container pt-4">
        <div className="row text-center pt-4">
          <div className="col-12 pt-3 pb-3 mr-1 text-secondary">
            <p>
              <FontAwesomeIcon icon={faQuestionCircle} />{" "}
              <strong>Need Help?</strong> Please contact to the administrator.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
