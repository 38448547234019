import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

export type TablePaginationProps = {
  page: number;
  pageSize: number;
  currentCount: number;
  totalCount: number;

  onNext: () => void;
  onPrev: () => void;
};

function TablePagination({
  page,
  pageSize,
  currentCount,
  totalCount,
  onNext,
  onPrev,
}: TablePaginationProps) {
  const hasPrev = page > 1;
  const hasNext = page * pageSize < totalCount;

  const handlePrev = (e: any) => {
    e.preventDefault();
    onPrev();
  };
  const handleNext = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <div className="row p-3">
      <div className="col-12 text-right">
        <div className="p-0">
          {page > 1 ? (page - 1) * pageSize + 1 : 1}-
          {currentCount === pageSize
            ? page * pageSize
            : (page - 1) * pageSize + currentCount}
          <Button
            variant="link"
            onClick={handlePrev}
            className="p-0 ml-2 mr-3"
            disabled={!hasPrev}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Button
            variant="link"
            onClick={handleNext}
            className="p-0"
            disabled={!hasNext}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TablePagination;
