/**
 * @file components/password/reset_password_complete.js
 */

import React from "react";
import { connect } from "react-redux";
import { PAGES } from "../../constants/pages";
import { Button } from "react-bootstrap";

class SetPasswordComplete extends React.Component {
  render() {
    return (
      <section className="reset-password-email-sent container">
        <h1 className="mb-3 mt-3 text-center">Success</h1>
        <div className="mb-5 text-center">
          Your new password has been set. You may go ahead and log in now.
        </div>
        <div>
          <Button
            href={PAGES.LOGIN}
            variant="primary"
            type="submit"
            block
            className="mb-3"
          >
            Login
          </Button>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(SetPasswordComplete);
