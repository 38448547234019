import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import {
  selectSpitKitErrors,
  selectSpitKitListFilters,
  selectSpitKitListPagination,
  selectSpitKitSummary,
  selectSpitKitSummaryIsLoading,
} from "src/store/SpitKit/SpitKitSelectors";
import {
  Pagination,
  SpitKitListFilters,
  SpitKitStatusSummary,
} from "src/store/SpitKit/Types";
import PageHeadingComponent from "src/components/global/PageHeading/PageHeadingComponent";
import SummaryCardComponent from "src/components/global/SummaryCard/SummaryCardComponent";
// @ts-ignore
import { connect } from "react-redux";
import SpitKitActionsModal, {
  OrderAction,
  OrderActionStatus,
} from "./SpitKitActionsModal";
import {
  doSetSpitKitErrors,
  doSetSpitKitListFilters,
  doSetSpitKitPagination,
} from "src/store/SpitKit/SpitKitActions";
import { DoDispatch } from "src/services/types/IStore";
import SpitKitTable, {
  BulkOrderActionRequest,
  OrderActionRequest,
} from "./SpitKitTable";
import usePrevious from "src/hooks/usePrevious";
import SpitKitFulfillOrdersModal from "./SpitKitFulfillOrdersModal";
import SpitKitImportOrderFulfillmentModal from "./SpitKitImportOrderFulfillmentModal";
import SpitKitBulkActionModal from "src/components/spitKit/SpitKitBulkActionModal";
import SpitKitExportOrdersModal from "src/components/spitKit/SpitKitExportOrdersModal";

export type SpitKitStateProps = {
  spitKitListPagination: Pagination;
  spitKitListFilters: SpitKitListFilters;

  spitKitSummary?: SpitKitStatusSummary;
  spitKitSummaryIsLoading: boolean;

  errors: Error[];
};

export type SpitKitDispatchProps = {
  setSpitKitListFilters: (filters: SpitKitListFilters) => void;
  setSpitKitListPagination: (pagination: Pagination) => void;
  clearSpitKitErrors: () => void;
};

export type SpitKitProps = {} & SpitKitStateProps &
  RouteComponentProps &
  SpitKitDispatchProps;

function SpitKit({
  spitKitListPagination,
  spitKitListFilters,
  spitKitSummaryIsLoading,
  spitKitSummary,
  errors,
  clearSpitKitErrors,
}: SpitKitProps) {
  const [orderToModify, setOrderToModify] = useState<string>();
  const [orderAction, setOrderAction] = useState<OrderAction>();
  const [orderBulkAction, setOrderBulkAction] = useState<OrderAction>();
  const [orderActionStatus, setOrderActionStatus] =
    useState<OrderActionStatus>();

  const [showOrderActionsModal, setShowOrderActionsModal] =
    useState<boolean>(false);
  const [showImportOrderFulfillmentModal, setShowImportOrderFulfillmentModal] =
    useState<boolean>(false);
  const [showExportOrdersModal, setShowExportOrdersModal] =
    useState<boolean>(false);

  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  const previousState = usePrevious({
    spitKitListFilters,
    spitKitListPagination,
    orderActionStatus,
  });

  // Load spit kit list when needed.
  useEffect(() => {
    // Here we only care of current page changes, all other are ignored, or else, we end up with an infinite loop.
    const spitKitListFiltersChanged =
      previousState?.spitKitListFilters !== spitKitListFilters;
    const onlyPageChanged =
      previousState?.spitKitListPagination?.page !== spitKitListPagination.page;
    const orderActionChanged =
      previousState?.orderActionStatus !== orderActionStatus;
    // An action (delete or cancel) has been completed when the orderActionStatus moves from any status to undefined.
    const actionCompleted =
      orderActionChanged && orderActionStatus === undefined;

    if (onlyPageChanged || spitKitListFiltersChanged || actionCompleted) {
      spitKitService.loadSpitKits({
        filters: spitKitListFilters,
        pagination: spitKitListPagination,
      });
    }
  }, [
    spitKitService,
    spitKitListFilters,
    spitKitListPagination,
    orderActionStatus,
    previousState,
  ]);

  const handleOrderActionRequested = ({
    orderId,
    action,
  }: OrderActionRequest) => {
    setOrderToModify(orderId);
    setOrderAction(action);
  };

  const handleBulkOrderActionRequested = ({
    action,
  }: BulkOrderActionRequest) => {
    setOrderBulkAction(action);
  };

  const handleCancel = () => {
    setOrderToModify(undefined);
    setOrderAction(undefined);
  };

  const handleOrderActionStatus = (status: OrderActionStatus) => {
    setOrderActionStatus(status);

    if (status === "completed") {
      setOrderToModify(undefined);
      setOrderAction(undefined);
    }
  };

  const closeSpitKitFulfillOrdersModal = () => setShowOrderActionsModal(false);

  const handleFulfillOrdersFinished = () => {
    closeSpitKitFulfillOrdersModal();
    setOrderActionStatus(undefined);
  };

  const closeImportOrderFulfillmentStatus = () =>
    setShowImportOrderFulfillmentModal(false);

  const handleImportOrderFulfillmentStatusFinished = () => {
    closeImportOrderFulfillmentStatus();
    setOrderActionStatus(undefined);
  };

  const handleBulkActionCanceled = () => {
    setOrderBulkAction(undefined);
  };

  const handleBulkActionStatus = (status: OrderActionStatus) => {
    setOrderActionStatus(status);

    if (status === "completed") {
      setOrderBulkAction(undefined);
    }
  };
  const handleExportOrders = () => setShowExportOrdersModal(true);
  const ordersPlaced =
    spitKitSummary &&
    Number(spitKitSummary.received) + Number(spitKitSummary.pending);
  return (
    <div className="container">
      <SpitKitActionsModal
        orderToModify={orderToModify}
        action={orderAction}
        onCancel={handleCancel}
        onActionStatus={handleOrderActionStatus}
      />

      <SpitKitExportOrdersModal
        show={showExportOrdersModal}
        onCancel={() => setShowExportOrdersModal(false)}
        onFinish={() => setShowExportOrdersModal(false)}
      />

      <SpitKitFulfillOrdersModal
        show={showOrderActionsModal}
        onCancel={closeSpitKitFulfillOrdersModal}
        onFinish={handleFulfillOrdersFinished}
      />

      {/* @ts-ignore */}
      <SpitKitImportOrderFulfillmentModal
        show={showImportOrderFulfillmentModal}
        onCancel={closeImportOrderFulfillmentStatus}
        onContinue={handleImportOrderFulfillmentStatusFinished}
      />

      {/* @ts-ignore */}
      <SpitKitBulkActionModal
        show={orderBulkAction === "delete"}
        onCancel={handleBulkActionCanceled}
        onActionStatus={handleBulkActionStatus}
      />

      <PageHeadingComponent title="DNA Collection Kit">
        <Button variant="info" className="mr-2" onClick={handleExportOrders}>
          Export Order(s)
        </Button>
      </PageHeadingComponent>

      {errors.length > 0 && (
        <Alert variant="danger" dismissible onClose={clearSpitKitErrors}>
          An Error occurred
        </Alert>
      )}

      <div className="row pt-4">
        <SummaryCardComponent
          title="Orders Placed"
          isLoading={spitKitSummaryIsLoading}
          count={ordersPlaced}
        />
        <SummaryCardComponent
          title="In Fulfillment"
          isLoading={spitKitSummaryIsLoading}
          count={spitKitSummary?.inFulfillment}
        />
      </div>

      {/* @ts-ignore */}
      <SpitKitTable
        onOrderActionRequested={handleOrderActionRequested}
        onBulkActionRequested={handleBulkOrderActionRequested}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitDispatchProps => ({
  setSpitKitListFilters: (filters: SpitKitListFilters) =>
    dispatch(doSetSpitKitListFilters(filters)),
  setSpitKitListPagination: (pagination: Pagination) =>
    dispatch(doSetSpitKitPagination(pagination)),
  clearSpitKitErrors: () => dispatch(doSetSpitKitErrors([])),
});

const mapStateToProps = createStructuredSelector<SpitKitStateProps>({
  spitKitListPagination: selectSpitKitListPagination,
  spitKitListFilters: selectSpitKitListFilters,
  spitKitSummary: selectSpitKitSummary,
  spitKitSummaryIsLoading: selectSpitKitSummaryIsLoading,

  errors: selectSpitKitErrors,
});

export default connect(
  mapStateToProps,
  // @ts-ignore
  mapDispatchToProps,
)(withRouter(SpitKit));
