/**
 * @file components/home.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    return (
      <section className="home container text-center ">
        <h1 className="mb-3 mt-3">Page not found</h1>
        <div>Well... this is embarrassing</div>
        <div>
          why don't we go <Link to="/">HOME</Link> instead
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(Home);
