import { URL_ROOT_API } from "../constants/api";
import { utils_user } from "../utils/utils_user";
import axios from "axios";

export const utils_api = {
  unauth_get(endpoint, success, fail) {
    return axios
      .get(`${URL_ROOT_API}${endpoint}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  unauth_post(endpoint, data, success, fail) {
    return axios
      .post(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_get(endpoint, success, fail) {
    return axios
      .get(`${URL_ROOT_API}${endpoint}`, {
        headers: {
          "x-api-key": utils_user.get_user_jwt(),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_post(endpoint, data, success, fail) {
    return axios
      .post(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: {
          "x-api-key": utils_user.get_user_jwt(),
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_put(endpoint, data, success, fail) {
    return axios
      .put(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: {
          // eslint-disable-next-line no-useless-computed-key
          ["x-api-key"]: utils_user.get_user_jwt(),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_delete(endpoint, data = null, success, fail) {
    // axios delete has some bugs, need to input header/data like so:
    return axios
      .delete(`${URL_ROOT_API}${endpoint}`, {
        headers: {
          // eslint-disable-next-line no-useless-computed-key
          ["x-api-key"]: utils_user.get_user_jwt(),
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_post_raw(endpoint, data, success, fail) {
    return axios
      .post(`${URL_ROOT_API}${endpoint}`, data, {
        headers: {
          "x-api-key": utils_user.get_user_jwt(),
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
};
