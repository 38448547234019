import { Container } from "inversify";
import React, { useContext } from "react";
import { ServiceName } from ".";

const ServicesContext = React.createContext<{ container: Container | null }>({
  container: null,
});

type Props = {
  container: Container;
  children: React.ReactNode;
};

export const ServiceProvider: React.FC<Props> = ({
  children,
  container,
}: Props) => (
  <ServicesContext.Provider value={{ container }}>
    {children}
  </ServicesContext.Provider>
);

export function useInjection<T>(identifier: ServiceName): T {
  const { container } = useContext(ServicesContext);
  if (!container) {
    throw new Error("Container is not accessible or hasn't been provided.");
  }
  return container.get<T>(identifier);
}
