/**
 * @file components/Modals/GroupSurveyModal.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal, Alert } from "react-bootstrap";
import { upload_ancestry } from "../../actions/ancestryAction";
import { PAGES } from "../../constants/pages";
import { UNEXPECTED_ERROR, RESPONSE_CODE } from "../../constants/errors";
import { LOCAL_STORAGE } from "../../constants/localStorage";
import { utils } from "../../utils/utils_general";

class UploadAncestryModal extends React.Component {
  constructor() {
    super();
    this.state = {
      buttonEnabled: false,
      selectedFileRegion: null,
      selectedFileAncesrty: null,

      errors: {},
    };
  }

  uploadAcnestry() {
    const data = new FormData();
    data.append("regions", this.state.selectedFileRegion);
    data.append("users", this.state.selectedFileAncesrty);

    this.setState({ buttonEnabled: false });

    return this.props
      .upload_ancestry(data)
      .then((response) => {
        utils.set_local_storage(
          LOCAL_STORAGE.UPLOAD_ANCESTRY_TOKEN,
          response.batch_id,
        );
        this.props.checkUploadStatus();
        this.closeModal();
      })
      .catch((error) => {
        this.setState({ buttonEnabled: true });

        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          } else if (
            error.response.status === RESPONSE_CODE["405_data_invalid"]
          ) {
            this.handlerError(error.response.data.msg);
          } else {
            this.handlerError(UNEXPECTED_ERROR);
          }
        }
      });
  }
  handlerError(error) {
    this.setState({ errors: { system: error } });
  }
  closeModal() {
    this.props.hideModal();

    this.setState({
      errors: {},
      selectedFileRegion: null,
      selectedFileAncesrty: null,
      buttonEnabled: false,
    });
  }
  setRegionFile = (event) => {
    this.setState({ selectedFileRegion: event.target.files[0] }, () => {
      this.checkIsValidFile();
    });
  };

  setAncestryFile = (event) => {
    this.setState({ selectedFileAncesrty: event.target.files[0] }, () => {
      this.checkIsValidFile();
    });
  };

  checkIsValidFile() {
    if (
      this.state.selectedFileRegion != null &&
      this.state.selectedFileAncesrty != null
    ) {
      this.setState({ buttonEnabled: true });
    } else {
      this.setState({ buttonEnabled: false });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={() => this.closeModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload Ancestry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Region File </p>
            <input
              type="file"
              className="form-control-file"
              id="regionFile"
              onChange={this.setRegionFile}
              accept=".csv"
            />
          </div>

          <hr></hr>
          <div>
            <p>Ancestry File </p>
            <input
              type="file"
              className="form-control-file"
              id="ancestryfile"
              onChange={this.setAncestryFile}
              accept=".csv"
            />
            <p className="pt-2">Do you wish to continue?</p>
          </div>

          {this.state.errors.system ? (
            <Alert variant="danger">
              <Alert.Heading>Error</Alert.Heading>
              <p>{this.state.errors.system}</p>
            </Alert>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.closeModal()}>
            Cancel
          </Button>
          <Button
            disabled={!this.state.buttonEnabled}
            variant="primary"
            onClick={() => this.uploadAcnestry()}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    survey: ownProps.survey,
  };
};

export default withRouter(
  connect(mapStateToProps, { upload_ancestry })(UploadAncestryModal),
);
