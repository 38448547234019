import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_logout } from "../api/api_logout";

export function logout_get() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      utils.clear_local_storage();
      dispatch({
        type: REDUX_ACTION.LOGOUT_GET,
        data: null,
      });
      return null;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_logout.logout(success, fail);
  };
}
