import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_request_invite = {
  request_invite(success, fail, data) {
    return utils_api.unauth_post(
      API_URL.REQUEST_APP_INVITE_POST,
      data,
      success,
      fail,
    );
  },
};
