import { AxiosError } from "axios";
import { ErrorResult } from "src/api/api";

export function normalizeError(error: unknown): Error {
  if (Object.hasOwn(error as any, "isAxiosError")) {
    const axiosError = error as AxiosError<ErrorResult>;
    return new Error(axiosError.response?.data?.msg || axiosError.message);
  }
  return error instanceof Error ? error : new Error(String(error));
}
