import { REDUX_ACTION } from "../constants/reduxAction.js";
import { jwtDecode } from "jwt-decode";
import { utils } from "../utils/utils_general";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { api_login } from "../api/api_login";

export function loginSuccess(dispatch, type) {
  return (response) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const jwt = response.data.data;
    const jwt_decoded = jwtDecode(jwt);
    dispatch({
      type: type,
      data: jwt_decoded,
    });
    utils.set_local_storage(LOCAL_STORAGE.USER, jwt);
    return jwt_decoded;
  };
}

export function login_get() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_GET);
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_login.get_login(success, fail);
  };
}

export function login_post(data) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_POST);
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_login.login(success, fail, data);
  };
}

export function login_mfa_otp_request_post(payload, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "login_mfa_otp_request_post",
      );
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_login.login_mfa_otp_request(success, payload, fail);
  };
}

export function login_mfa_otp_validation_post(otpId, otp, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "login_mfa_otp_validation_post",
      );
    }
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_POST);
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_login.login_mfa_otp_validation(success, fail, otpId, otp);
  };
}

export function set_login_user_from_local_storage() {
  return (dispatch) => {
    const jwt = utils.get_local_storage(LOCAL_STORAGE.USER);
    const jwt_decoded = jwtDecode(jwt);
    if (jwt_decoded) {
      dispatch({
        type: REDUX_ACTION.LOGIN_FROM_LOCAL_STORAGE,
        data: jwt_decoded,
      });
    }
  };
}
