import { Action } from "redux";
import { StateKey } from "src/components/global/StateList/StateList";
import { SPIT_KIT_REDUCER_NAMESPACE } from "./Constants";

const types = {
  SET_SPIT_KIT_PAGINATION: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitPagination`,
  SET_SPIT_KIT_LIST_FILTERS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitListFilters`,
  SET_SPIT_KIT_LIST: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitList`,
  SET_SPIT_KIT_LOADING: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitLoading`,

  SET_SPIT_KIT_SUMMARY: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitSummary`,
  SET_SPIT_KIT_SUMMARY_LOADING: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitSummaryLoading`,

  SET_CURRENT_SPIT_KIT: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetCurrentSpitKit`,

  SET_SPIT_KIT_ERRORS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitErrors`,

  SET_SPIT_KIT_FULFILLED_LIST: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitFulfilledList`,
  SET_SPIT_KIT_LIST_IS_FULFILLING_NEW_ORDERS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitListFulfillingNewOrders`,
  SET_SPIT_KIT_IMPORT_RESULT: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitImportResult`,
  SET_SPIT_KIT_SELECTED_ORDER_IDS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSelectedOrderIds`,
  SET_SPIT_KIT_BULK_OPERATIONS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitBulkOperations`,
  SET_SPIT_KIT_BULK_OPERATION_STATUS: `${SPIT_KIT_REDUCER_NAMESPACE}.doSetSpitKitBulkOperationStatus`,
};

export type Requester = Pick<
  SpitKitOrderEntry,
  "first_name" | "last_name" | "email"
>;

export function fullName({ first_name, last_name }: Requester) {
  return [first_name, last_name].filter((ownName) => ownName).join(" ");
}

export type RGCStatus =
  | "ordered"
  | "pending"
  | "in_fulfillment"
  | "shipped"
  | "error"
  | "delivered"
  | "collected";

export type SpectrumStatus =
  | "Entered"
  | "Approved"
  | "Check Inventory"
  | "Started"
  | "Completed"
  | "Shipped"
  | "Delivered"
  | "Freeze/ On Hold"
  | "Cancelled"
  | "Suspended";

export type SpitKitOrderEntry<
  TAddressLine1 = string,
  TAddressLine2 = string,
  TCity = string,
  TState = StateKey,
  TZip = string,
  TOrderPhone = string,
  TGoogleVerified = boolean,
  TTrackingNumber = string,
> = {
  uuid: string;
  order_id: string;
  app_user_id: string;
  address_line1: TAddressLine1;
  address_line2?: TAddressLine2;
  city: TCity;
  state: TState;
  zip: TZip;
  order_phone?: TOrderPhone;
  google_verified: TGoogleVerified;
  serial_id?: string;
  sku_id?: string;
  status: RGCStatus;
  created: string;
  updated: string;
  tracking_number?: TTrackingNumber;
  email: string;
  first_name: string;
  last_name: string;
};

export type SpitKitStatusSummary = {
  received: number;
  pending: number;
  inFulfillment: number;
};

export type SpitKitListFilters = {
  searchTerm?: string;
  orderBy?: keyof SpitKitOrderEntry;
  orderByDirection?: "asc" | "desc";
  status?: RGCStatus;
};

export type SpitKitExportFilters = {
  statuses?: RGCStatus[];
};

export type Pagination = {
  page: number;
  pageSize: number;
  resultCount: number;
  totalCount: number;
  totalPages: number;
};

export type PaginationActionData = {
  page?: number;
  pageSize?: number;
  resultCount?: number;
  totalCount?: number;
  totalPages?: number;
};

export type SpitKitImportResult = {
  notFound: string[];
  wrongStatus: string[];
  success: string[];
  duplicates: string[];
};

export type BulkOperationStatus =
  | "enqueued"
  | "running"
  | "completed"
  | "failed";

export type SpitKitBulkOperation = {
  order_id: string;
  operationStatus: BulkOperationStatus;
  reason?: Error;
};

export type SpitKitSelectedOrders = SpitKitOrderEntry[];

export function spitKitImportResultFullySuccessful({
  notFound,
  wrongStatus,
  duplicates: duplicate,
}: SpitKitImportResult) {
  return notFound.length + wrongStatus.length + duplicate.length === 0;
}

export type SpitKitDataTypes =
  | SpitKitListFilters
  | SpitKitOrderEntry[]
  | SpitKitOrderEntry
  | SpitKitStatusSummary
  | PaginationActionData
  | SpitKitImportResult
  | SpitKitSelectedOrders
  | SpitKitBulkOperation[]
  | SpitKitBulkOperation
  | Error[];

export interface SpitKitAction extends Action<string> {
  data?: SpitKitDataTypes;
}

export function newSpitKitOrder(): SpitKitOrderEntry {
  return {
    uuid: "",
    order_id: "",
    app_user_id: "",
    address_line1: "",
    city: "",
    state: "AK",
    zip: "",
    google_verified: false,
    status: "ordered",
    created: "",
    updated: "",
    email: "",
    first_name: "",
    last_name: "",
  };
}

export function hasUser({ app_user_id, email }: SpitKitOrderEntry): boolean {
  return app_user_id?.length > 0 && email?.length > 0;
}

export default types;
