import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
// console.log('path', (/#!(\/.*)$/.exec(window.location.hash)) );

// if (path) {
//     history.replace(path);
// }

export default history;
