import React, { useEffect } from "react";

// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import usePrevious from "src/hooks/usePrevious";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import {
  selectSpitKitFulfilledList,
  selectSpitKitListIsFulfillingNewOrders,
} from "src/store/SpitKit/SpitKitSelectors";
import { SpitKitOrderEntry } from "src/store/SpitKit/Types";
import GenericModal from "src/components/global/GenericModal/GenericModal";
import { useCsvExporter } from "src/hooks/useCsvExporter";
import { SpitKitCsvExportSettings } from "src/components/spitKit/constants/SpitKitCsvExportSettings";

export type SpitKitFulfillOrdersModalStateProps = {
  spitKitListIsFulfillingNewOrders: boolean;
  spitKitFulfilledList: SpitKitOrderEntry[];
};

// These are the props directly used when returning the component: <SpitKitFulfillOrdersModal prop1={} prop2={} />
export type SpitKitFulfillOrdersModalComponentProps = {
  show?: boolean;
  onCancel?: () => void;
  onFinish?: () => void;
};

export type SpitKitFulfillOrdersModalProps =
  SpitKitFulfillOrdersModalComponentProps & SpitKitFulfillOrdersModalStateProps;

function SpitKitFulfillOrdersModal({
  show = false,
  onCancel = () => {},
  onFinish = () => {},
  spitKitListIsFulfillingNewOrders,
  spitKitFulfilledList,
}: SpitKitFulfillOrdersModalProps) {
  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  const previousState = usePrevious({ spitKitListIsFulfillingNewOrders });

  const { exportToCsv } = useCsvExporter(SpitKitCsvExportSettings);

  useEffect(() => {
    const exportJustFinished =
      previousState?.spitKitListIsFulfillingNewOrders &&
      !spitKitListIsFulfillingNewOrders;

    if (exportJustFinished) {
      exportToCsv({
        data: spitKitFulfilledList.map((order) => ({
          ...order,
          status: "in_fulfillment",
        })),
        fileName: "Fulfilled Orders.csv",
      });

      onFinish();
    }
  }, [
    spitKitFulfilledList,
    spitKitListIsFulfillingNewOrders,
    previousState,
    onFinish,
    exportToCsv,
  ]);

  const handleContinue = async () => {
    await spitKitService.fulfillNewSpitKitOrders();
  };

  return (
    <GenericModal
      title={"Fulfill New Orders"}
      show={show}
      onCancel={onCancel}
      onContinue={handleContinue}
      proceeding={spitKitListIsFulfillingNewOrders}
    >
      <p>
        I acknowledge that I have reviewed this data file and consent to
        providing this data back to Regeneron or Research within my
        Organization.
      </p>
      <p>
        <strong>
          Note: you will only be able to export orders that are in "ordered"
          status. Once exported, this will change their status to "In
          Fulfillment"
        </strong>
      </p>
      <p>Do you wish to continue?</p>
    </GenericModal>
  );
}

const mapDispatchToProps = {};

const mapStateToProps =
  createStructuredSelector<SpitKitFulfillOrdersModalStateProps>({
    spitKitListIsFulfillingNewOrders: selectSpitKitListIsFulfillingNewOrders,
    spitKitFulfilledList: selectSpitKitFulfilledList,
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpitKitFulfillOrdersModal);
