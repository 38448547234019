export const REDUX_ACTION = {
  LOGIN_POST: "login_post",
  LOGIN_GET: "login_get",
  LOGIN_FROM_LOCAL_STORAGE: "LOGIN_FROM_LOCAL_STORAGE",
  LOGOUT_GET: "logout_get",

  SURVEYS_GET: "surveys_get",
  SURVEYS_ALL_DOWNLOAD: "surveys_all_download",

  SURVEY_PUBLISH: "survey_publish",
  SURVEY_DELETE: "survey_unpublish",

  SURVEYS_EXPORT: "surveys_download",
  SURVEYS_EXPORT_STATUS: "surveys_download_status",
  SURVEY_DOWNLOAD: "survey_download",

  SPINNER: "spinner",
  SPINNER_COMPLETE: "spinner_complete",

  APP_USERS_POST: "users",
};
