import React, { FormEvent, useRef, useState } from "react";
import { Alert, Button, Card, ListGroup } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import GenericModal, {
  GenericModalProps,
} from "../global/GenericModal/GenericModal";
// @ts-ignore
import { connect } from "react-redux";
import {
  selectSpitKitErrors,
  selectSpitKitImportResult,
} from "src/store/SpitKit/SpitKitSelectors";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { DoDispatch } from "src/services/types/IStore";
import { doSetSpitKitErrors } from "src/store/SpitKit/SpitKitActions";
import {
  SpitKitImportResult,
  spitKitImportResultFullySuccessful,
} from "src/store/SpitKit/Types";

type SpitKitImportResultListProps = {
  title: string;
  variant: "success" | "danger" | "warning";
  entries?: string[];
};

function SpitKitImportResultList({
  title,
  entries,
  variant,
}: SpitKitImportResultListProps) {
  if (entries === undefined || entries.length === 0) {
    return null;
  }

  return (
    <Card className="mb-2">
      <Card.Header className={`text-${variant}`}>{title}</Card.Header>
      <ListGroup variant="flush">
        {entries.map((entry) => (
          <ListGroup.Item key={entry}>{entry}</ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
}

type SpitKitImportOrderFulfillmentModalStateProps = {
  errors: Error[];
  spitKitImportResult?: SpitKitImportResult;
};

type SpitKitImportOrderFulfillmentModalDispatchProps = {
  clearSpitKitErrors: () => void;
};

// These are the props directly used when returning the component: <SpitKitImportOrderFulfillmentModal prop1={} prop2={} />
export type SpitKitImportOrderFulfillmentModalComponentProps = Pick<
  GenericModalProps,
  "show" | "onCancel" | "onContinue"
>;

export type SpitKitImportOrderFulfillmentModalProps =
  SpitKitImportOrderFulfillmentModalComponentProps &
    SpitKitImportOrderFulfillmentModalStateProps &
    SpitKitImportOrderFulfillmentModalDispatchProps;

function SpitKitImportOrderFulfillmentModal({
  show = false,
  spitKitImportResult,
  onCancel = () => {},
  onContinue = () => {},
  errors,
  clearSpitKitErrors,
}: SpitKitImportOrderFulfillmentModalProps) {
  const [file, setFile] = useState<File>();
  const [isProceeding, setIsProceeding] = useState<boolean>(false);
  const [performed, setPerformed] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  const handleFileChanged = ({
    currentTarget,
  }: FormEvent<HTMLInputElement>) => {
    clearSpitKitErrors();
    setFile((currentTarget.files || [])[0]);
    setPerformed(false);
  };

  const handleCancel = () => {
    clearSpitKitErrors();
    onCancel();
    setPerformed(false);
  };

  const handleAlternate = () => {
    clearSpitKitErrors();
    onContinue();
    setPerformed(false);
  };

  const handleContinue = async () => {
    if (file === undefined) {
      return;
    }
    setIsProceeding(true);
    try {
      await spitKitService.importSpitKitOrderFulfillment(file);
      setPerformed(true);
    } finally {
      setIsProceeding(false);
      setFile(undefined);
      if (!!fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const fullySuccessful =
    performed &&
    errors.length === 0 &&
    spitKitImportResult !== undefined &&
    spitKitImportResultFullySuccessful(spitKitImportResult);

  const partiallySuccessful =
    performed && errors.length === 0 && !fullySuccessful;

  return (
    <GenericModal
      title={"Import Orders"}
      show={show}
      onCancel={handleCancel}
      onContinue={handleContinue}
      canProceed={!!file}
      proceeding={isProceeding}
      isAlternate={errors.length > 0 || performed}
      alternateAction={<Button onClick={handleAlternate}>Ok</Button>}
    >
      <input
        className="form-control-file mb-2"
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={handleFileChanged}
      />
      <>
        {fullySuccessful && (
          <Alert variant="success">All Orders have been Imported</Alert>
        )}
      </>
      <>
        {partiallySuccessful && (
          <>
            <SpitKitImportResultList
              title="Successful Imports"
              variant="success"
              entries={spitKitImportResult?.success}
            />
            <Alert variant="warning">
              Some order statues could not be updated
            </Alert>
            <SpitKitImportResultList
              title="Duplicated"
              variant="danger"
              entries={spitKitImportResult?.duplicates}
            />
            <SpitKitImportResultList
              title="Not Found"
              variant="danger"
              entries={spitKitImportResult?.notFound}
            />
            <SpitKitImportResultList
              title="Wrong Status"
              variant="danger"
              entries={spitKitImportResult?.wrongStatus}
            />
          </>
        )}
      </>
      <ListGroup className="mt-3">
        {errors.map((e, index) => (
          <ListGroup.Item key={index} className="text-danger">
            {e.message}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </GenericModal>
  );
}

const mapDispatchToProps = (
  dispatch: DoDispatch,
): SpitKitImportOrderFulfillmentModalDispatchProps => ({
  clearSpitKitErrors: () => dispatch(doSetSpitKitErrors([])),
});

const mapStateToProps =
  createStructuredSelector<SpitKitImportOrderFulfillmentModalStateProps>({
    errors: selectSpitKitErrors,
    spitKitImportResult: selectSpitKitImportResult,
  });

export default connect(
  mapStateToProps,
  // @ts-ignore
  mapDispatchToProps,
)(SpitKitImportOrderFulfillmentModal);
