import { inject, injectable } from "inversify";
import { api, Result } from "src/api/api";
import { API_URL } from "src/constants/api";
import { normalizeError } from "src/services/normalizeError";
import {
  doSetCurrentSpitKit,
  doSetSpitKitBulkOperations,
  doSetSpitKitErrors,
  doSetSpitKitFulfilledList,
  doSetSpitKitImportResult,
  doSetSpitKitList,
  doSetSpitKitListFulfillingNewOrders,
  doSetSpitKitLoading,
  doSetSpitKitPagination,
  doSetSpitKitSummary,
  doSetSpitKitSummaryLoading,
} from "src/store/SpitKit/SpitKitActions";
import { selectSpitKitCurrentOrder } from "src/store/SpitKit/SpitKitSelectors";
import {
  Pagination,
  RGCStatus,
  SpitKitBulkOperation,
  SpitKitExportFilters,
  SpitKitImportResult,
  SpitKitListFilters,
  SpitKitOrderEntry,
  SpitKitStatusSummary,
} from "src/store/SpitKit/Types";
import * as IStore from "./types/IStore";
import {
  CreateSpitKitData,
  UpdateSpitKitData,
} from "./types/validations/SpitKitValidations";

export type SpitKitListQuery = {
  filters: SpitKitListFilters;
  pagination: Pagination;
};

export type UpdateOrderCommand = {
  order_id: string;
} & UpdateSpitKitData;

export interface ISpitKitService {
  createSpitKitOrder(orderData: CreateSpitKitData): Promise<void>;
  updateSpitKitOrder(updateCommand: UpdateOrderCommand): Promise<void>;

  loadSpitKit(order_id: string): Promise<void>;

  loadSpitKits(query: SpitKitListQuery): Promise<void>;

  fulfillNewSpitKitOrders(): Promise<void>;

  importSpitKitOrderFulfillment(file: File): Promise<void>;
  getUserSpitKits(userId: string): Promise<SpitKitOrderEntry[]>;

  exportSpitKitOrders(
    filters: SpitKitExportFilters,
  ): Promise<SpitKitOrderEntry[]>;

  bulkDeleteSpitKitOrders(orders: SpitKitOrderEntry[]): Promise<void>;
}

type StatusSummaryEntry = {
  status: string;
  amount: number;
};

type LoadSpitKitsResultData = {
  metadata: StatusSummaryEntry[];
  orders: SpitKitOrderEntry[];
};

const bulkStatusMessages: Record<keyof SpitKitImportResult, string> = {
  notFound: "Not Found",
  wrongStatus: "Wrong Status",
  success: "Success",
  duplicates: "Duplicates",
};
@injectable()
export class SpitKitService implements ISpitKitService {
  private ordersCache?: Result<LoadSpitKitsResultData> = undefined;

  @inject("store")
  private store!: IStore.IStore;

  private dispatchError(error: unknown) {
    const normalizedError = normalizeError(error);
    this.store.dispatch(doSetSpitKitErrors([normalizedError]));
  }

  private async doUpdateStatus(
    order_id: string,
    status: RGCStatus,
  ): Promise<void> {
    await api({
      endpoint: API_URL.ORDERS_UPDATE_STATUS(order_id),
      method: "PUT",
      data: {
        status,
      },
    });
  }

  private async updateStatus(
    order_id: string,
    status: RGCStatus,
  ): Promise<void> {
    try {
      this.doUpdateStatus(order_id, status);
      this.ordersCache = undefined;
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async createSpitKitOrder(data: CreateSpitKitData): Promise<void> {
    this.store.dispatch(doSetSpitKitLoading());

    try {
      const result = await api<SpitKitOrderEntry>({
        endpoint: API_URL.ORDERS,
        data,
        method: "POST",
      });

      const incompleteSpitKit = selectSpitKitCurrentOrder(
        this.store.getState(),
      ) as SpitKitOrderEntry;

      this.store.dispatch(
        doSetCurrentSpitKit({
          ...incompleteSpitKit,
          ...result.data,
        }),
      );

      this.ordersCache = undefined;
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async updateSpitKitOrder({
    order_id,
    ...data
  }: UpdateOrderCommand): Promise<void> {
    this.store.dispatch(doSetSpitKitLoading());

    try {
      await api<SpitKitOrderEntry>({
        endpoint: API_URL.ORDERS_UPDATE(order_id),
        data,
        method: "PUT",
      });

      this.ordersCache = undefined;
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async loadSpitKit(order_id: string): Promise<void> {
    this.store.dispatch(doSetSpitKitErrors([]));
    this.store.dispatch(doSetSpitKitLoading());

    try {
      const result = await this.doLoadSpitKits();
      const { orders } = result.data;

      const entry: SpitKitOrderEntry | undefined = orders.find(
        (order) => order.order_id === order_id,
      );

      if (entry === undefined) {
        throw new Error("Not Found");
      }

      this.store.dispatch(doSetCurrentSpitKit(entry));
    } catch (error) {
      this.dispatchError(error);
    }
  }

  private async doLoadSpitKits(): Promise<Result<LoadSpitKitsResultData>> {
    if (this.ordersCache === undefined) {
      this.ordersCache = await api<LoadSpitKitsResultData>({
        endpoint: API_URL.ORDERS,
      });
    }

    return this.ordersCache;
  }

  async getUserSpitKits(userId: string): Promise<SpitKitOrderEntry[]> {
    const result = await this.doLoadSpitKits();
    const { orders } = result.data;
    return orders.filter(({ app_user_id }) => app_user_id === userId);
  }

  async loadSpitKits({ filters, pagination }: SpitKitListQuery): Promise<void> {
    this.store.dispatch(doSetSpitKitLoading());
    this.store.dispatch(doSetSpitKitSummaryLoading());

    const {
      searchTerm,
      status,
      orderBy = "created",
      orderByDirection = "desc",
    } = filters;
    const { page, pageSize } = pagination;

    try {
      const result = await this.doLoadSpitKits();

      const { metadata, orders } = result.data;

      const dataAfterSearch = !searchTerm
        ? orders
        : orders.filter(
            ({
              first_name,
              last_name,
              serial_id,
              sku_id,
              order_id,
              status,
            }) => {
              return (
                `${first_name} ${last_name} ${first_name}`.includes(
                  searchTerm,
                ) ||
                serial_id?.includes(searchTerm) ||
                sku_id?.includes(searchTerm) ||
                order_id?.includes(searchTerm) ||
                status?.includes(searchTerm)
              );
            },
          );

      const dataAfterStatusFilter = dataAfterSearch.filter(
        ({ status: orderStatus }) => !status || status === orderStatus,
      );

      const entries: SpitKitOrderEntry[] = dataAfterStatusFilter
        .sort((entry1, entry2) => {
          const firstValue = (entry1[orderBy] || "") as string;
          const secondValue = (entry2[orderBy] || "") as string;

          if (orderByDirection === "asc") {
            return firstValue.localeCompare(secondValue);
          }

          return secondValue.localeCompare(firstValue);
        })
        .filter(
          (entry, index) =>
            index >= (page - 1) * pageSize && index < page * pageSize,
        );

      const spitKitSummary: SpitKitStatusSummary = metadata.reduce(
        ({ received, pending, inFulfillment }, { status, amount }) => ({
          received: status === "ordered" ? amount : received,
          pending: status === "pending" ? amount : pending,
          inFulfillment: status === "in_fulfillment" ? amount : inFulfillment,
        }),
        {
          received: 0,
          pending: 0,
          inFulfillment: 0,
        },
      );

      this.store.dispatch(
        doSetSpitKitPagination({
          resultCount: entries.length,
          totalCount: dataAfterStatusFilter.length,
          totalPages: Math.ceil(dataAfterStatusFilter.length / pageSize),
        }),
      );
      this.store.dispatch(doSetSpitKitList(entries));
      this.store.dispatch(doSetSpitKitSummary(spitKitSummary));
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async fulfillNewSpitKitOrders(): Promise<void> {
    this.store.dispatch(doSetSpitKitListFulfillingNewOrders());

    try {
      const result = await api<SpitKitOrderEntry[]>({
        endpoint: API_URL.ORDERS_EXPORT,
        method: "POST",
      });

      const exportedOrders = result.data;
      this.ordersCache = undefined;

      this.store.dispatch(doSetSpitKitFulfilledList(exportedOrders));
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async importSpitKitOrderFulfillment(file: File): Promise<void> {
    try {
      const data = new FormData();
      data.append("orders", file);

      const result = await api<SpitKitImportResult>({
        endpoint: API_URL.ORDERS_IMPORT,
        data,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
      });

      this.ordersCache = undefined;
      this.store.dispatch(doSetSpitKitImportResult(result.data));
    } catch (error) {
      this.dispatchError(error);
    }
  }

  async exportSpitKitOrders({
    statuses,
  }: SpitKitExportFilters): Promise<SpitKitOrderEntry[]> {
    const status = statuses?.join(",");
    const result = await api<LoadSpitKitsResultData>({
      endpoint: API_URL.ORDERS,
      params: {
        ...(status ? { status } : {}),
      },
    });

    return result.data.orders;
  }

  async bulkDeleteSpitKitOrders(orders: SpitKitOrderEntry[]): Promise<void> {
    const operations: SpitKitBulkOperation[] = orders.map<SpitKitBulkOperation>(
      ({ order_id }) => ({ order_id, operationStatus: "running" }),
    );

    this.store.dispatch(doSetSpitKitBulkOperations(operations));
    this.ordersCache = undefined;

    try {
      const response = await api<SpitKitImportResult>({
        endpoint: API_URL.ORDERS_UPDATE_STATUS_BULK,
        method: "PUT",
        data: {
          status: "deleted",
          order_ids: operations.map(({ order_id }) => order_id),
        },
      });
      const { data } = response;

      const operationsResults: SpitKitBulkOperation[] = Object.entries(
        data,
      ).reduce((accumulate, [resultType, orderIds]) => {
        const statusType = resultType as keyof SpitKitImportResult;
        const succeeded = statusType === "success";
        return [
          ...accumulate,
          ...orderIds.map<SpitKitBulkOperation>((order_id) => ({
            order_id,
            operationStatus: succeeded ? "completed" : "failed",
            reason: succeeded
              ? undefined
              : normalizeError(bulkStatusMessages[statusType]),
          })),
        ];
      }, [] as SpitKitBulkOperation[]);

      this.store.dispatch(doSetSpitKitBulkOperations(operationsResults));
    } catch (error) {
      this.store.dispatch(
        doSetSpitKitBulkOperations(
          operations.map(({ order_id }) => ({
            order_id,
            operationStatus: "failed",
            reason: normalizeError(error),
          })),
        ),
      );
    }
  }
}
