import React from "react";
import BSSpinner from "react-bootstrap/Spinner";

class Spinner extends React.Component {
  render() {
    return (
      <div className="global-spinner p-3">
        <BSSpinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </BSSpinner>
      </div>
    );
  }
}

export default Spinner;
