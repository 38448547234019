import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_reset_password } from "../api/api_reset_password";

export function send_reset_password_email_post(data) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_reset_password.send_reset_password_email(success, fail, data);
  };
}

export function update_reset_password_put(password) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_reset_password.update_reset_password(success, fail, password);
  };
}
