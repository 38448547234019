/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import ResendUserModel from "../modals/resend-user-modal";
import SuspendUserModel from "../modals/suspend-user-modal";
import { PAGES } from "../../constants/pages";

class AppUserList extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      users: null,
      hoverUserItem: null,
      modalIsOpen: false,
      selectedUserItem: null,
      currentPage: 1,
      targetAction: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.displayUsers &&
      (prevProps.displayUsers
        ? prevProps.displayUsers[0].app_user_id
        : null) !== this.props.displayUsers[0].app_user_id
    ) {
      this.setState({ users: this.props.displayUsers });
      this.setState({ currentPage: this.props.currentPage });
    }
    if (
      this.props.displayUsers &&
      this.state.users &&
      this.props.displayUsers !== this.state.users
    ) {
      this.setState({ users: this.props.displayUsers });
      this.setState({ currentPage: this.props.currentPage });
    }
  }

  formateDate(datestring) {
    const date = new Date(datestring);
    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
  }

  handleHover(id) {
    this.setState({ hoverUserItem: id });
  }

  handleStatus(user) {
    this.props.errors("");
    this.setState({ selectedUserItem: user });
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  handleSuspend(user, action) {
    this.props.errors("");
    this.setState({ selectedUserItem: user });
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    this.setState({ targetAction: action });
  }

  handleSort(column) {
    this.props.refreshUsers(column);
  }

  renderLoadingProcess() {
    return (
      <div className="text-center">
        <div className="lds-dual-ring"></div> Downloading
      </div>
    );
  }

  renderAction(user, status) {
    if (status === "active" || status === "enrollment") {
      return (
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-1 btn-small"
            onClick={() => this.handleSuspend(user, "suspend")}
          >
            Suspend
          </Button>
        </div>
      );
    }

    if (status === "lockout") {
      return (
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-1 btn-small"
            onClick={() => this.handleSuspend(user, "activate")}
          >
            ReActivate
          </Button>
        </div>
      );
    }

    if (status === "delete") {
      return (
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-1 btn-small"
            onClick={() => this.handleSuspend(user, "activate")}
          >
            Reactivate
          </Button>
        </div>
      );
    }

    if (status === "suspend") {
      return (
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-1 btn-small"
            onClick={() => this.handleSuspend(user, "activate")}
          >
            Reactivate
          </Button>
        </div>
      );
    }

    if (status === "pending") {
      return (
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-1 btn-small"
            onClick={(e) => this.handleSuspend(user, e)}
          >
            Resend Invite
          </Button>
        </div>
      );
    }
  }

  renderUsers() {
    const users = this.state.users;

    let addModalClose = () => {
      this.setState({ modalIsOpen: false });
    };

    return (
      <div className="text-small">
        {this.state.selectedUserItem &&
        this.state.selectedUserItem.status === "pending" ? (
          <ResendUserModel
            showModal={this.state.modalIsOpen}
            user={this.state.selectedUserItem}
            hideModal={addModalClose}
          />
        ) : (
          <SuspendUserModel
            showModal={this.state.modalIsOpen}
            user={this.state.selectedUserItem}
            hideModal={addModalClose}
            action={this.state.targetAction}
          />
        )}

        <div className="card  mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              {users
                ? "Displaying " + this.state.users.length + " Users(s)"
                : "No data found for your search"}{" "}
            </h6>
          </div>
          {users ? (
            <div className="card-body">
              <div className="table-responsive">
                <table className="table" width="100%">
                  <thead>
                    <tr>
                      <th width="30%">User ID</th>
                      <th width="30%">
                        <Button
                          variant="link"
                          className={
                            "column-header sort-by " +
                            (this.props.sortColumn === "email"
                              ? this.props.sortOrder === 1
                                ? "sort-asc"
                                : "sort-dsc"
                              : "")
                          }
                          onClick={() => this.handleSort("email")}
                        >
                          Email
                        </Button>
                      </th>
                      <th width="20%">
                        <Button
                          variant="link"
                          className={
                            "column-header sort-by " +
                            (this.props.sortColumn === "first_name"
                              ? this.props.sortOrder === 1
                                ? "sort-asc"
                                : "sort-dsc"
                              : "")
                          }
                          onClick={() => this.handleSort("first_name")}
                        >
                          Name
                        </Button>
                      </th>
                      <th width="8%">
                        <Button
                          variant="link"
                          className={
                            "column-header sort-by " +
                            (this.props.sortColumn === "status"
                              ? this.props.sortOrder === 1
                                ? "sort-asc"
                                : "sort-dsc"
                              : "")
                          }
                          onClick={() => this.handleSort("status")}
                        >
                          Status
                        </Button>
                      </th>
                      <th width="12%" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {users.map((user, key) => (
                      <tr
                        key={key}
                        id={user.app_user_id}
                        className="row-min-height"
                        onMouseEnter={() => this.handleHover(user.app_user_id)}
                        onMouseLeave={() => this.handleHover(null)}
                      >
                        <td>
                          <Link
                            to={{
                              pathname: PAGES.APP_USERS_DETAIL,
                              state: {
                                user: user,
                                currentPage: this.state.currentPage,
                                paginationSize: this.props.paginationSize,
                                sortColumn: this.props.sortColumn,
                                sortOrder: this.props.sortOrder,
                              },
                            }}
                          >
                            <small>
                              {" "}
                              <u>{user.app_user_id}</u>
                            </small>
                          </Link>
                        </td>

                        <td>
                          <Link
                            to={{
                              pathname: PAGES.APP_USERS_DETAIL,
                              state: {
                                user: user,
                                currentPage: this.state.currentPage,
                                paginationSize: this.props.paginationSize,
                                sortColumn: this.props.sortColumn,
                                sortOrder: this.props.sortOrder,
                              },
                            }}
                          >
                            <small>
                              <u>{user.email}</u>
                            </small>
                          </Link>
                        </td>
                        <td className=" text-capitalize text-gray-800 mb-0 blurred text-">
                          <small>
                            {user.first_name} {user.last_name}
                          </small>
                        </td>
                        <td className={" text-capitalize "}>
                          <small>{user.status}</small>
                        </td>
                        <td className="p-0">
                          {this.state.hoverUserItem === user.app_user_id ? (
                            <div
                              className="text-center"
                              style={{ marginTop: "5px" }}
                            >
                              {this.renderAction(user, user.status)}

                              {/*
                                                        user.status === 'suspend' ?  
                                                        <Button variant="outline-primary" size="sm" className="mr-1 btn-small" onClick={() => this.handleSuspend(user,"activate")}>Active</Button> : 
                                                        <Button variant="outline-primary" size="sm" className="mr-1 btn-small" onClick={() => this.handleSuspend(user,"suspend")}>Suspend</Button>
                                                       */}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <React.Fragment>{this.renderUsers()}</React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(connect(mapStateToProps, {})(AppUserList));
