/**
 * @file components/login/header.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Logout from "./logout";
import { PAGES } from "src/constants/pages";
import { APP_NAME } from "src/constants/config";
import { FEATURE } from "src/constants/features";
import classNames from "classnames";

const MenuLink = withRouter(function ({ page, status, pageDisplay, location }) {
  const { pathname } = location;

  return (
    <Nav.Item as="li">
      <Nav.Link
        href={page}
        className={classNames("pr-2", {
          "font-weight-bold": pathname.match(page),
          disabled: status === false,
        })}
      >
        {pageDisplay}
      </Nav.Link>
    </Nav.Item>
  );
});

class AuthHeader extends React.Component {
  render() {
    const firstName = this.props.loginUser.first_name;
    return (
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow">
        <a className="navbar-brand text-white" href="/#">
          <h3>{APP_NAME}</h3>
        </a>

        <ul className="navbar-nav mr-auto text-white">
          <li>
            <a href="#/" className="text-white">
              Partner Portal
            </a>
          </li>
        </ul>
        <Nav>
          <MenuLink page={PAGES.DASHBOARD} status pageDisplay={"Home"} />

          {FEATURE.SURVEY ? (
            <MenuLink page={PAGES.SURVEYS} status pageDisplay={"Surveys"} />
          ) : null}
          {FEATURE.ANCESTRY ? (
            <MenuLink page={PAGES.ANCESTRY} status pageDisplay={"Ancestry"} />
          ) : null}
          {FEATURE.POLL ? (
            <MenuLink page={PAGES.POLLS} status pageDisplay={"Polls"} />
          ) : null}
          {FEATURE.DNA_COLLECTION_KIT ? (
            <MenuLink
              page={PAGES.SPIT_KIT}
              status
              pageDisplay={"DNA Collection Kit"}
            />
          ) : null}

          {
            <MenuLink
              page={PAGES.APP_USERS}
              status
              pageDisplay={"User Management"}
            />
          }
          {
            <MenuLink
              page={PAGES.SYSTEM_CONFIG}
              status
              pageDisplay={"System Configs"}
            />
          }
          {
            <MenuLink
              page={PAGES.SYSTEM_REPORTS}
              status
              pageDisplay={"Reports"}
            />
          }
        </Nav>

        <div className="col-2 p-0 text-right text-white text-capitalize">
          {firstName} &nbsp; <Logout />
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(connect(mapStateToProps, {})(AuthHeader));
