/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PAGES } from "../../constants/pages";
import { download_survey_single_get } from "../../actions/surveyAction";
import PublishSurveyModal from "../modals/publish-survey-modal";
import UnPublishSurveyModal from "../modals/unpublish-survey-modal";
// import GroupSurveyModal from '../modals/group-survey-modal';

import {
  SURVEY_ERROR,
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
} from "../../constants/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStar } from "@fortawesome/free-solid-svg-icons";

class SurveyList extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      surveys: null,
      groups: null,
      hoverSurveyItem: null,
      modalIsOpen: false,
      groupModalIsOpen: false,
      selectedSurveyItem: null,
      currentPage: 1,
      currentDownloads: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.displaySurveys &&
      (prevProps.displaySurveys
        ? prevProps.displaySurveys[0].survey_id
        : null) !== this.props.displaySurveys[0].survey_id
    ) {
      this.setState({ surveys: this.props.displaySurveys });
      this.setState({ currentPage: this.props.surveys.pagination.page_num });
      this.setState({ groups: this.props.surveyGroups });
    }
    if (
      this.props.displaySurveys &&
      this.state.surveys &&
      this.props.displaySurveys !== this.state.surveys
    ) {
      this.setState({ surveys: this.props.displaySurveys });
      this.setState({ currentPage: this.props.surveys.pagination.page_num });
      this.setState({ groups: this.props.surveyGroups });
    }
  }

  formateDate(datestring) {
    const date = new Date(datestring);
    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
  }

  handleHover(id) {
    this.setState({ hoverSurveyItem: id });
  }

  handlePublish(survey) {
    this.props.errors("");
    this.setState({ selectedSurveyItem: survey });
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  findHighlighted(surveyId) {
    if (this.state.groups) {
      const group = this.state.groups.find((q) => q.survey_id === surveyId);

      if (group) {
        if (group.group_code_list === "important")
          return (
            <span className="text-warning">
              <FontAwesomeIcon icon={faStar} />
            </span>
          );
        else return "";
      } else return "";
    }
  }

  handleDownloadSurvey(id) {
    this.setState({ currentDownloads: [...this.state.currentDownloads, id] });
    this.props.errors("");
    return this.props
      .download_survey_single_get(id)
      .then((response) => {
        document.location.href = response.data;
        this.updateDownloadProcess(id);
      })
      .catch((error) => {
        this.updateDownloadProcess(id);
        if (error.response) {
          switch (error.response.status) {
            case RESPONSE_CODE["404_notfound"]:
              this.props.errors(SURVEY_ERROR.SURVEY_NOT_FOUND);
              break;
            default:
              this.props.errors(UNEXPECTED_ERROR);
          }
        } else {
          this.props.errors(UNEXPECTED_ERROR);
        }
      });
  }

  updateDownloadProcess(id) {
    var updatedCurrentDownloads = [...this.state.currentDownloads];
    var index = updatedCurrentDownloads.indexOf(id);
    if (index !== -1) {
      updatedCurrentDownloads.splice(index, 1);
      this.setState({ currentDownloads: updatedCurrentDownloads });
    }
  }

  renderDownloadProcess() {
    return (
      <div className="text-center">
        <div className="lds-dual-ring"></div> Downloading
      </div>
    );
  }

  renderSurveys() {
    if (!this.state.surveys) {
      return null;
    }
    const surveys = this.state.surveys;

    let addModalClose = () => {
      this.setState({ modalIsOpen: false });
      this.setState({ groupModalIsOpen: false });
      this.props.refreshSurvey();
    };

    return (
      <div className="text-small">
        {this.state.selectedSurveyItem &&
        this.state.selectedSurveyItem.status === "unpublish" ? (
          <PublishSurveyModal
            showModal={this.state.modalIsOpen}
            survey={this.state.selectedSurveyItem}
            hideModal={addModalClose}
          />
        ) : (
          <UnPublishSurveyModal
            showModal={this.state.modalIsOpen}
            survey={this.state.selectedSurveyItem}
            hideModal={addModalClose}
          />
        )}

        <div className="card mb-4">
          <div className="card-header py-3">
            <p className="m-0  text-gray-600">
              Displaying {surveys.length} survey(s) &nbsp;&nbsp;
            </p>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table" width="100%">
                <thead>
                  <tr>
                    <th width="10%">Survey ID</th>
                    <th width="40%">Title</th>
                    <th width="10%">Type</th>
                    <th width="10%">Status</th>
                    <th width="15%">Date</th>
                    <th width="15%">...</th>
                  </tr>
                </thead>

                <tbody>
                  {surveys.map((survey, key) => (
                    <tr
                      key={key}
                      id={survey.limesurvey_id}
                      className=""
                      style={{ height: "70px" }}
                      onMouseEnter={() =>
                        this.handleHover(survey.limesurvey_id)
                      }
                      onMouseLeave={() => this.handleHover(null)}
                    >
                      <td>{survey.limesurvey_id}</td>
                      <td>
                        {" "}
                        <Link
                          to={{
                            pathname: PAGES.SURVEY_DETAIL,
                            state: {
                              survey: survey,
                              currentPage: this.state.currentPage,
                              groups: this.state.groups,
                            },
                          }}
                        >
                          <u>{survey.title}</u>
                        </Link>{" "}
                      </td>
                      <td className=" text-capitalize text-gray-800 mb-0 ">
                        {" "}
                        {this.findHighlighted(survey.survey_id)}{" "}
                      </td>
                      <td className={" text-capitalize " + survey.status}>
                        {survey.status}{" "}
                      </td>
                      <td className="">{this.formateDate(survey.created)}</td>
                      <td className="p-0">
                        {this.state.currentDownloads.some(
                          (id) => id === survey.survey_id,
                        ) ? (
                          <React.Fragment>
                            {this.renderDownloadProcess()}
                          </React.Fragment>
                        ) : this.state.hoverSurveyItem ===
                          survey.limesurvey_id ? (
                          <div
                            className="text-center"
                            style={{ marginTop: "5px" }}
                          >
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mr-1 btn-small"
                              onClick={() => this.handlePublish(survey)}
                            >
                              {survey.status === "publish"
                                ? "Unpublish"
                                : "Publish"}
                            </Button>

                            {/*
                                        <Button variant="outline-primary" size="sm btn-small" onClick={() => this.handleDownloadSurvey(survey.survey_id)}>Export</Button>
                                         */}
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <React.Fragment>{this.renderSurveys()}</React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, { download_survey_single_get })(SurveyList),
);
