import { utils } from "./utils_general";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { jwtDecode } from "jwt-decode";

export const utils_user = {
  get_user_jwt: () => {
    return utils.get_local_storage(LOCAL_STORAGE.USER) || null;
  },
  get_user: () => {
    const user_jwt = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
    return user_jwt ? jwtDecode(user_jwt) : {};
  },
};
