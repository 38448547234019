import React, { useEffect, useState } from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
  selectSpitKitList,
  selectSpitKitListFilters,
  selectSpitKitListLoading,
  selectSpitKitListPagination,
  selectSpitKitSelectedOrders,
} from "src/store/SpitKit/SpitKitSelectors";
import {
  fullName,
  Pagination,
  RGCStatus,
  SpitKitListFilters,
  SpitKitOrderEntry,
  SpitKitSelectedOrders,
} from "src/store/SpitKit/Types";
import SortColumnHeader, {
  SortRequestedEvent,
} from "src/components/global/DataTable/SortColumnHeader";
// @ts-ignore
import { connect } from "react-redux";
import TablePagination from "src/components/global/DataTable/TablePagination";
import { OrderAction } from "./SpitKitActionsModal";
import classNames from "classnames";
import {
  doSetSpitKitListFilters,
  doSetSpitKitPagination,
  doSetSpitKitSelectedOrders,
} from "src/store/SpitKit/SpitKitActions";
import { DoDispatch } from "src/services/types/IStore";
import TableBody from "src/components/global/DataTable/TableBody";
import { debounce } from "lodash";
import { SpitKitDate } from "./SpitKitDate";
// @ts-ignore
import SpitKitStatus from "./SpitKitStatus";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";

import "./SpitKitTable.scss";

export type SpitKitTableStateProps = Pick<
  SpitKitReducerState,
  | "spitKitListPagination"
  | "spitKitListFilters"
  | "spitKitList"
  | "spitKitListLoading"
  | "spitKitSelectedOrders"
>;

export type SpitKitTableDispatchProps = {
  setSpitKitListFilters: (filters: SpitKitListFilters) => void;
  setSpitKitListPagination: (pagination: Pagination) => void;
  setSpitKitSelectedOrders: (orders: SpitKitSelectedOrders) => void;
};

export type OrderActionRequest = {
  orderId: string;
  action: OrderAction;
};

export type BulkOrderActionRequest = {
  action: OrderAction;
};

export type SpitKitComponentProps = {
  onOrderActionRequested: ({ orderId, action }: OrderActionRequest) => void;
  onBulkActionRequested: (action: BulkOrderActionRequest) => void;
};

export type SpitKitProps = SpitKitComponentProps &
  SpitKitTableStateProps &
  RouteComponentProps &
  SpitKitTableDispatchProps;

function SpitKitTable({
  spitKitListPagination,
  spitKitListFilters,
  spitKitList,
  spitKitListLoading,
  spitKitSelectedOrders,
  setSpitKitListFilters,
  setSpitKitListPagination,
  setSpitKitSelectedOrders,
}: SpitKitProps) {
  const [allVisibleItemsSelected, setAllVisibleItemsSelected] =
    useState<boolean>(false);

  const { orderBy, orderByDirection, status } = spitKitListFilters;
  const { page, pageSize, resultCount, totalCount, totalPages } =
    spitKitListPagination;

  const setPage = (page: number) =>
    setSpitKitListPagination({
      ...spitKitListPagination,
      page: Math.min(page, Math.max(totalPages, 1)),
    });

  const handleNext = () => setPage(Math.min(page + 1, totalPages));

  const handlePrev = () => setPage(Math.max(page - 1, 1));

  const handleSortRequested = ({
    columnName,
    currentSortOrder,
  }: SortRequestedEvent) => {
    let newOrderByDirection = orderByDirection;
    if (columnName === orderBy) {
      newOrderByDirection = currentSortOrder === "asc" ? "desc" : "asc";
    } else {
      newOrderByDirection = "asc";
    }

    setPage(1);
    setSpitKitListFilters({
      orderBy: (columnName as keyof SpitKitOrderEntry) || "order_id",
      orderByDirection: newOrderByDirection,
    });
  };

  const doSearch = debounce(
    (value: string) => {
      setSpitKitListFilters({
        searchTerm: value.length > 0 ? value : undefined,
      });
      setPage(1);
    },
    500,
    { leading: false },
  );

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    e.persist();
    doSearch(e.currentTarget.value);
  };

  const handleStatusFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = e.currentTarget.value;
    setSpitKitListFilters({
      status: value.length > 0 ? (value as RGCStatus) : undefined,
    });
    setPage(1);
  };

  const toggleAllVisibleItemsSelected = (selected: boolean) => {
    const allVisibleIds = spitKitList.map(({ order_id }) => order_id);

    setSpitKitSelectedOrders(
      selected
        ? Array.from(new Set([...spitKitSelectedOrders, ...spitKitList]))
        : spitKitSelectedOrders.filter(
            ({ order_id }) => !allVisibleIds.includes(order_id),
          ),
    );
  };

  const handleSelectByStatus = (status: RGCStatus | "All" | "None") => {
    switch (status) {
      case "All": {
        toggleAllVisibleItemsSelected(true);
        return;
      }
      case "None": {
        toggleAllVisibleItemsSelected(false);
        return;
      }
      default: {
        const allVisibleOrders = spitKitList.map(({ order_id }) => order_id);
        const visibleSelectedItems = spitKitList.filter(
          ({ status: orderStatus }) => orderStatus === status,
        );

        setSpitKitSelectedOrders([
          ...visibleSelectedItems,
          ...spitKitSelectedOrders.filter(
            ({ order_id }) => !allVisibleOrders.includes(order_id),
          ),
        ]);
      }
    }
  };

  useEffect(() => {
    const visibleSelectedIds = spitKitList.filter((order) =>
      spitKitSelectedOrders.includes(order),
    );
    setAllVisibleItemsSelected(
      spitKitList.length > 0 &&
        visibleSelectedIds.length === spitKitList.length,
    );
  }, [spitKitSelectedOrders, spitKitList]);

  useEffect(() => {
    return () => doSearch.cancel();
  }, [doSearch]);

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex">
            <div className="m-0 text-gray-600 pt-1 mr-auto">
              <Dropdown className="d-inline mr-2">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSelectByStatus("All")}>
                    All
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSelectByStatus("None")}>
                    None
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectByStatus("ordered")}
                  >
                    Ordered
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectByStatus("pending")}
                  >
                    Pending
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectByStatus("in_fulfillment")}
                  >
                    In Fulfillment
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectByStatus("shipped")}
                  >
                    Shipped
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSelectByStatus("error")}>
                    Error
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {`Displaying ${resultCount} Order(s)`}
            </div>
            <div className="mr-3">
              <Form.Control
                as="select"
                onChange={handleStatusFilterChange}
                className={classNames("custom-select", {
                  "text-muted": status === undefined,
                })}
              >
                <option value={""}>Filter by Status</option>
                <option value={"ordered"}>Ordered</option>
                <option value={"pending"}>Pending</option>
                <option value={"in_fulfillment"}>In Fulfillment</option>
                <option value={"shipped"}>Shipped</option>
                <option value={"error"}>Error</option>
              </Form.Control>
            </div>
            <div>
              <Form.Control
                placeholder="Search"
                // TODO: fix types
                // @ts-ignore
                onChange={handleSearch}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="table-responsive">
          <table className="table table-condensed table-striped table-hover">
            <thead>
              <tr>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Order ID"}
                    active={orderBy === "order_id"}
                    sortOrder={orderByDirection}
                    columnName={"order_id"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Name"}
                    active={orderBy === "first_name"}
                    sortOrder={orderByDirection}
                    columnName={"first_name"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Barcode"}
                    active={orderBy === "serial_id"}
                    sortOrder={orderByDirection}
                    columnName={"serial_id"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Status"}
                    active={orderBy === "status"}
                    sortOrder={orderByDirection}
                    columnName={"status"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Created"}
                    active={orderBy === "created"}
                    sortOrder={orderByDirection}
                    columnName={"created"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
                <th className="text-nowrap">
                  <SortColumnHeader
                    text={"Updated"}
                    active={orderBy === "updated"}
                    sortOrder={orderByDirection}
                    columnName={"updated"}
                    onSortRequested={handleSortRequested}
                  />
                </th>
              </tr>
            </thead>
            <TableBody
              isLoading={spitKitListLoading}
              columns={9}
              rows={spitKitList.length}
              emptyMessage="No Orders Found"
            >
              {spitKitList.map((order) => {
                const {
                  order_id,
                  serial_id,
                  status,
                  created,
                  updated,
                  ...requester
                } = order;
                const requesterFullName = fullName(requester);

                return (
                  <tr key={order_id}>
                    <td className="align-middle">{order_id}</td>
                    <td
                      className="align-middle full-name-cell"
                      title={requesterFullName}
                    >
                      {requesterFullName}
                    </td>
                    <td className="align-middle">{serial_id}</td>
                    <td className="align-middle">
                      <SpitKitStatus status={status} />
                    </td>
                    <td className="align-middle">
                      <SpitKitDate date={created} />
                    </td>
                    <td className="align-middle">
                      <SpitKitDate date={updated} />
                    </td>
                  </tr>
                );
              })}
            </TableBody>
          </table>
        </Card.Body>
      </Card>

      <TablePagination
        page={page}
        pageSize={pageSize}
        currentCount={resultCount}
        totalCount={totalCount}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </>
  );
}

const mapDispatchToProps = (
  dispatch: DoDispatch,
): SpitKitTableDispatchProps => ({
  setSpitKitListFilters: (filters: SpitKitListFilters) =>
    dispatch(doSetSpitKitListFilters(filters)),
  setSpitKitListPagination: (pagination: Pagination) =>
    dispatch(doSetSpitKitPagination(pagination)),
  setSpitKitSelectedOrders: (orderIds: SpitKitSelectedOrders) =>
    dispatch(doSetSpitKitSelectedOrders(orderIds)),
});

const mapStateToProps = createStructuredSelector<SpitKitTableStateProps>({
  spitKitListPagination: selectSpitKitListPagination,
  spitKitListFilters: selectSpitKitListFilters,
  spitKitList: selectSpitKitList,
  spitKitListLoading: selectSpitKitListLoading,
  spitKitSelectedOrders: selectSpitKitSelectedOrders,
});

export default connect(
  mapStateToProps,
  // TODO: fix types
  // @ts-ignore
  mapDispatchToProps,
)(withRouter(SpitKitTable));
