/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UNEXPECTED_ERROR, RESPONSE_CODE } from "../../constants/errors";
import { PAGES } from "../../constants/pages";
import { get_config } from "../../actions/systemAction";
import moment from "moment";

import { ls_import_surveys_post } from "../../actions/surveyAction";
import { import_poll } from "../../actions/pollAction";

import { FEATURE } from "../../constants/features";
import SyncOperations from "src/components/system/SyncOperations";

class System extends React.Component {
  uploadIntervalID = 0;

  constructor() {
    super();
    this.state = {
      errors: {},
      config: null,
      pollSyncResults: [],
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  handleSync() {
    this.handlerError("");

    return this.props.ls_import_surveys_post().catch((error) => {
      if (error.response?.status === RESPONSE_CODE["401_unauthorized"]) {
        this.props.history.push(PAGES.LOGIN);
      } else {
        throw error;
      }
    });
  }

  handlePollSync() {
    this.handlerError("");
    this.setState({ pollSyncResults: [] });

    return this.props
      .import_poll()
      .then(({ data }) => this.setState({ pollSyncResults: data.data }))
      .catch((error) => {
        if (error.response?.status === RESPONSE_CODE["401_unauthorized"]) {
          this.props.history.push(PAGES.LOGIN);
        } else {
          throw error;
        }
      });
  }

  getConfig() {
    return this.props
      .get_config()
      .then((response) => {
        this.setState({
          config: response, //since sorting isn't implemented yet
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        } else {
          this.handlerError(UNEXPECTED_ERROR);
        }
      });
  }

  handlerError(error) {
    this.setState({ errors: { system: error } });
  }

  momentFormat(sourceDate) {
    if (sourceDate) return moment(sourceDate).format("MMMM D YYYY  h:mm:ss a");
    return "NA";
  }

  renderConfigRow(name, value) {
    return (
      <li className="list-group-item d-flex justify-content-between align-items-center">
        {name}
        <span className="badge badge-default badge-pill">{value}</span>
      </li>
    );
  }

  renderConfig() {
    console.log("CONFIG", this.state.config);
    if (!this.state.config) {
      return null;
    }

    const data = this.state.config;

    return (
      <ul className="list-group list-group-flush mt-3 mb-3">
        <li className="list-group-item list-group-item-primary">
          Application Settings
        </li>
        {this.renderConfigRow(
          "Application token expiry time",
          data.app_expiry.login_min + " minutes",
        )}
        {this.renderConfigRow(
          "Registration invitation expiry time",
          data.app_expiry.reg_link_min + " minutes",
        )}
        {this.renderConfigRow(
          "Reset password link expiry",
          data.app_expiry.password_reset_link_min + " minutes",
        )}
        {this.renderConfigRow(
          "Password validity",
          data.app_expiry.login_day + " days",
        )}
        {this.renderConfigRow(
          "Maximum failed login attempts ",
          data.max_failed_login_attempt + " times",
        )}
        {this.renderConfigRow(
          "Password reset maximum attempts",
          data.pwd_reset_max + " times",
        )}
        {this.renderConfigRow(
          "OTP validity",
          data.otp_lifetime_secs + " seconds",
        )}

        <li className="list-group-item list-group-item-primary">
          Email Settings
        </li>
        {this.renderConfigRow("From email", data.general_from)}
        {this.renderConfigRow("Reply-to email", data.reply_to)}

        <li className="list-group-item list-group-item-primary">
          Application Consent Settings
        </li>
        {this.renderConfigRow(
          "Consent life",
          data.app_expiry.consent_day + " days",
        )}
        {this.renderConfigRow(
          "Consent file download time",
          data.app_expiry.consent_file_sec + " seconds",
        )}

        <li className="list-group-item list-group-item-primary">
          Survey Settings
        </li>

        {this.renderConfigRow("Limesurvey instance", data.limesurvey.api_url)}
        {this.renderConfigRow(
          "Inactive quickpoll group name (api only)",
          data.limesurvey.inactive_quickpoll_group_name,
        )}
      </ul>
    );
  }

  render() {
    const { pollSyncResults } = this.state;
    const { succeed, failed, warnings } = (pollSyncResults || []).reduce(
      (accumulate, { summary, error }) => {
        if (error) {
          return { ...accumulate, failed: accumulate.failed + 1 };
        }
        if (summary.failed?.length > 0) {
          return { ...accumulate, warnings: accumulate.warnings + 1 };
        }
        return { ...accumulate, succeed: accumulate.succeed + 1 };
      },
      { succeed: 0, failed: 0, warnings: 0 },
    );

    return (
      <section className="surveys container">
        <div className="mt-3 mb-3">
          <div className="mb-3 text-muted mb-0 text-gray-800">
            {" "}
            <span className="h3">System</span> &nbsp; &nbsp;
            {this.renderConfig()}
            <ul className="list-group list-group-flush mt-3 mb-3">
              <li className="list-group-item list-group-item-primary">
                Sync Operations{" "}
              </li>

              {FEATURE.SURVEY ? (
                <li className="list-group-item">
                  <SyncOperations
                    operationName="Sync Surveys"
                    synchedMessage="Syched surveys from LimeSurvey."
                    onSync={() => this.handleSync()}
                  />
                </li>
              ) : null}

              {FEATURE.POLL ? (
                <li className="list-group-item">
                  <SyncOperations
                    operationName="Sync Polls"
                    synchedMessage={
                      <>
                        Syched polls from LimeSurvey.
                        <span className="badge badge-success ml-2">
                          Succeed {succeed}
                        </span>
                        <span className="badge badge-warning ml-2">
                          Warnings {warnings}
                        </span>
                        <span className="badge badge-danger ml-2">
                          Failed {failed}
                        </span>
                      </>
                    }
                    onSync={() => this.handlePollSync()}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, { get_config, ls_import_surveys_post, import_poll })(
    System,
  ),
);
