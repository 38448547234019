/**
 * @file components/login/header.js
 */

import React from "react";
import { APP_NAME } from "../../constants/config";

class UnauthHeader extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow">
        <a className="navbar-brand text-white" href="/#">
          <h3>{APP_NAME}</h3>
        </a>
      </nav>
    );
  }
}

export default UnauthHeader;
