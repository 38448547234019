/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { UNEXPECTED_ERROR, RESPONSE_CODE } from "../../constants/errors";
import { PAGES } from "../../constants/pages";
import { utils } from "../../utils/utils_general";
import { LOCAL_STORAGE } from "../../constants/localStorage";
import {
  get_ancestry_logs,
  ancestry_upload_status_get,
} from "../../actions/ancestryAction";
import UploadAncestryModal from "../modals/upload-ancerstry-modal";
import moment from "moment";
import { FEATURE } from "../../constants/features";

class Ancestry extends React.Component {
  uploadIntervalID = 0;

  constructor() {
    super();
    this.state = {
      errors: {},
      anlogs: null,
      currentPage: 1,
      modalIsOpen: false,
      modalError: false,
      isUploading: false,
      uploadCompleted: false,
      ancestryUploadToken: null,
    };

    this.initCheckUploadStatus = this.initCheckUploadStatus.bind(this);
    this.checkUploadAncestryIsCompleted =
      this.checkUploadAncestryIsCompleted.bind(this);
  }

  componentDidMount() {
    if (!FEATURE.ANCESTRY)
      //Feature Flag
      return;

    const uploadAncestryToken =
      utils.get_local_storage(LOCAL_STORAGE.UPLOAD_ANCESTRY_TOKEN) || null;

    this.getAncestry();

    if (uploadAncestryToken) {
      this.setState({ isUploading: true }, () => {
        this.initCheckUploadStatus();
      });
    }
  }

  initCheckUploadStatus() {
    const delayTime = 10000;
    const uploadAncestryToken =
      utils.get_local_storage(LOCAL_STORAGE.UPLOAD_ANCESTRY_TOKEN) || null;

    clearInterval(this.uploadIntervalID);
    this.setState({ isUploading: true });
    this.setState({ ancestryUploadToken: uploadAncestryToken }, () => {
      this.checkUploadAncestryIsCompleted();
    });

    //check status after every [delayTime]
    this.uploadIntervalID = setInterval(
      this.checkUploadAncestryIsCompleted,
      delayTime,
    );
  }

  checkUploadAncestryIsCompleted() {
    return this.props
      .ancestry_upload_status_get(this.state.ancestryUploadToken)
      .then((response) => {
        if (response.status === "completed") {
          this.setState({ isUploading: false, uploadCompleted: true });
          this.uploadReset();
          this.getAncestry();
        }
      })
      .catch((error) => {
        this.uploadReset();
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        }
      });
  }

  uploadReset() {
    clearInterval(this.uploadIntervalID);
    this.setState({ ancestryUploadToken: null });
    utils.remove_local_storage(LOCAL_STORAGE.UPLOAD_ANCESTRY_TOKEN);
  }

  handleUpload() {
    this.setState({ modalIsOpen: true });
  }

  getAncestry() {
    return this.props
      .get_ancestry_logs()
      .then((response) => {
        this.setState({
          anlogs: response, //since sorting isn't implemented yet
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        } else {
          this.handlerError(UNEXPECTED_ERROR);
        }
      });
  }

  handlerError(error) {
    this.setState({ errors: { system: error } });
  }

  momentFormat(sourceDate) {
    if (sourceDate) return moment(sourceDate).format("MMMM D YYYY  h:mm:ss a");
    return "NA";
  }

  lastDayOfImport() {
    const { anlogs } = this.state;
    if (!anlogs || anlogs.length === 0) {
      return null;
    }
    const recentlogs = anlogs[0];
    return moment(recentlogs.action_date).format("MMMM D YYYY  h:mm:ss a");
    //return moment(recentlogs.action_date, "YYYYMMDD").fromNow();
  }
  renderDetails(log, option) {
    const logJSON = JSON.parse(log.description);
    let returnData = "";
    switch (option) {
      case "successusers":
        returnData = logJSON.users.success;
        break;

      case "successusers_per":
        returnData =
          (logJSON.users.success * 100) /
          (logJSON.users.fail + logJSON.users.success);
        returnData = Math.round(returnData, 1);
        break;

      case "failusers":
        returnData = logJSON.users.fail;
        break;

      case "totalusers":
        returnData = logJSON.users.fail + logJSON.users.success;
        break;

      case "regionsuccess":
        returnData = logJSON.regions.success;
        break;

      default:
        returnData = "NA";
    }
    return returnData;
  }
  renderlogs() {
    if (!this.state.anlogs) {
      return null;
    }

    const data = this.state.anlogs;

    return (
      <table className="table" width="100%">
        <thead>
          <tr>
            <th width="20%"> Date</th>
            <th width="20%">Total Users</th>
            <th width="15%">Ancestry Updated</th>
            <th width="15%">Ancestry Fail</th>
            <th width="15%">Regions</th>
            <th width="15%">Success %</th>
          </tr>
        </thead>

        <tbody>
          {data.map((log, key) => (
            <tr key={key}>
              <td className="  text-gray-800 mb-0 ">
                {" "}
                <small>{this.momentFormat(log.action_date)}</small>
              </td>
              <td className="p-0 text-center">
                {this.renderDetails(log, "totalusers")}
              </td>
              <td className="p-0 text-center">
                {this.renderDetails(log, "successusers")}{" "}
              </td>
              <td className="p-0 text-center">
                {this.renderDetails(log, "failusers")}
              </td>
              <td className="p-0 text-center">
                {this.renderDetails(log, "regionsuccess")}
              </td>
              <td className="p-0 text-center text-success">
                {this.renderDetails(log, "successusers_per")}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderUploadProcess() {
    return (
      <div className="downloading-survey-details">
        <div className="text-xs font-weight-bold text-success mb-1">
          <div className="lds-dual-ring mr-2"></div>Uploading Ancestry
        </div>
      </div>
    );
  }

  renderUploadCompleted() {
    return (
      <div className="downloading-survey-details">
        <div className="text-xs font-weight-bold text-success mb-1">
          Ancestry files uploaded succesfully
        </div>
      </div>
    );
  }

  render() {
    if (!FEATURE.ANCESTRY)
      return (
        <div className="text-center">
          <h3>Not available</h3>
        </div>
      );

    let addModalClose = () => {
      this.setState({ modalIsOpen: false });
    };

    return (
      <section className="surveys container">
        <React.Fragment>
          <UploadAncestryModal
            showModal={this.state.modalIsOpen}
            hideModal={addModalClose}
            errorMsg={this.state.modalError}
            checkUploadStatus={() => this.initCheckUploadStatus()}
          ></UploadAncestryModal>
        </React.Fragment>

        <div className="mt-3 mb-3">
          <div className="mb-3 text-muted mb-0 text-gray-800">
            {" "}
            <span className="h3">Ancestry</span> &nbsp; &nbsp;
            <Button
              className="mr-3 btn-info"
              disabled={this.state.isUploading}
              onClick={() => this.handleUpload()}
            >
              Upload Ancestry
            </Button>
            {this.state.isUploading ? (
              <React.Fragment>{this.renderUploadProcess()}</React.Fragment>
            ) : this.state.uploadCompleted ? (
              <React.Fragment>{this.renderUploadCompleted()}</React.Fragment>
            ) : null}
            <p></p>
            <p className="">
              {" "}
              <small>Last upload was done on {this.lastDayOfImport()}</small>
            </p>
          </div>

          {this.renderlogs()}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, { get_ancestry_logs, ancestry_upload_status_get })(
    Ancestry,
  ),
);
