import { AxiosError } from "axios";
import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { UNEXPECTED_ERROR } from "src/constants/errors";

// These are the props directly used when returning the component: <SyncOperationsError prop1={} prop2={} />
export type SyncOperationsErrorComponentProps = {
  error?: Error;
};

export type SyncOperationsErrorProps = SyncOperationsErrorComponentProps;

const getErrorDescription = (error: Error): string | undefined => {
  if (!("response" in error)) {
    return undefined;
  }

  const errorResponse = error as AxiosError<any>;
  const { data } = errorResponse.response ?? {};

  if (!("msg" in data)) {
    return undefined;
  }

  return data["msg"] as string;
};

type ErrorDetailsProps = {
  error: Error;
  style?: CSSStyleDeclaration;
};

const ErrorDetails = React.forwardRef(
  ({ error, ...otherProps }: ErrorDetailsProps, ref) => {
    const { style, ...popoverProps } = otherProps;
    const errorDescription = getErrorDescription(error);

    return (
      // @ts-ignore
      <Popover
        id="error-popover"
        {...popoverProps}
        // @ts-ignore
        style={{ ...(style || {}), maxWidth: "500px" }}
        // @ts-ignore
        ref={ref}
      >
        <Popover.Title as="h3">Error details</Popover.Title>
        <Popover.Content>
          <p>{error.message}</p>
          {errorDescription !== undefined && <pre>{errorDescription}</pre>}
        </Popover.Content>
      </Popover>
    );
  },
);

function SyncOperationsError({ error }: SyncOperationsErrorProps) {
  if (!error) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      rootClose
      overlay={<ErrorDetails error={error} />}
    >
      <Button
        variant="link"
        size="sm"
        className="text-danger p-0"
        style={{ minWidth: 0 }}
      >
        {UNEXPECTED_ERROR}
      </Button>
    </OverlayTrigger>
  );
}

export default SyncOperationsError;
