/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { utils } from "../../utils/utils_general";
import { PAGES } from "../../constants/pages";
import { post_app_user_list } from "../../actions/appUserAction";

import SuspendUserModal from "../modals/suspend-user-modal";
import ResendUserModal from "../modals/resend-user-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPollH,
  faUserCheck,
  faPoll,
} from "@fortawesome/free-solid-svg-icons";
import { user_kpi } from "../../actions/reportAction";
import { UNEXPECTED_ERROR } from "../../constants/errors";

class AppUserDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      user: null,
      app_user_id: null,
      status: null,
      suspended: false,
      currentPage: 1,
      isDownloading: false,
      paginationSize: null,
      modalIsOpen: false,
      quick_poll: 0,
      completed_surveys: 0,
      targetAction: null,
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.user &&
      !utils.is_obj_empty(this.props.location.state.user)
    ) {
      this.setState({
        app_user_id: this.props.location.state.user.app_user_id,
      });
      this.setState({
        paginationSize: this.props.location.state.paginationSize,
      });
      this.setState(
        { currentPage: this.props.location.state.currentPage },
        () => this.postUsers(),
      );
      this.getKPI(this.props.location.state.user.app_user_id);
    } else {
      this.props.history.push(PAGES.APP_USERS);
    }
  }

  postUsers() {
    const data = {
      page_num: this.state.currentPage,
      page_size: this.state.paginationSize,
      email: this.props.location.state.user.email,
      sort_item: this.props.location.state.sortColumn,
      sort_asc: this.props.location.state.sortOrder,
    };
    return this.props
      .post_app_user_list(data)
      .then((response) => {
        this.setState({
          user: response.find(
            (newUser) => newUser.app_user_id === this.state.app_user_id,
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        this.handlerError(UNEXPECTED_ERROR);
      });
  }

  getKPI(user_id) {
    return this.props
      .user_kpi(user_id)
      .then((response) => {
        this.setState({ completed_surveys: response.completed_surveys });
        this.setState({ quick_poll: response.quick_poll });
      })
      .catch((error) => {
        console.log(error);
        this.handlerError(UNEXPECTED_ERROR);
      });
  }

  formateDate(datestring) {
    const date = new Date(datestring);
    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
  }

  handlerError(error) {
    this.setState({ errors: { system: error } });
  }

  handleSuspend(action) {
    this.setState({ errors: "" });
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    this.setState({ targetAction: action });
  }

  renderDownloadProcess() {
    return (
      <div className="downloading-survey-details">
        <div className="lds-dual-ring"></div> Downloading
      </div>
    );
  }

  renderUserSummary() {
    if (!this.state.user) {
      return null;
    }
    return (
      <div className="row pt-4">
        <div className="col-xl-4 col-md-4 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Surveys Taken
                  </div>
                  <div className="h3 mb-0 font-weight-bold text-muted">
                    {this.state.completed_surveys}
                  </div>
                </div>
                <div className="col-auto  fa-2x  text-gray-300">
                  <FontAwesomeIcon icon={faPollH} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-4 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Status
                  </div>
                  <div className="h3 mb-0 font-weight-bold text-muted text-capitalize">
                    {this.state.user.status}
                  </div>
                </div>
                <div className="col-auto  fa-2x  text-gray-300">
                  <FontAwesomeIcon icon={faUserCheck} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-4 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Quick Polls
                  </div>
                  <div className="h3 mb-0 font-weight-bold text-muted">
                    {this.state.quick_poll}
                  </div>
                </div>
                <div className="col-auto  fa-2x  text-gray-300">
                  <FontAwesomeIcon icon={faPoll} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderAction() {
    if (!this.state.user) return null;

    if (this.state.user.status === "active") {
      return (
        <div>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend("suspend")}
              className="btn text-primary"
            >
              Suspend
            </Button>
          </p>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend("delete")}
              className="btn text-primary"
            >
              Delete
            </Button>
          </p>
        </div>
      );
    }

    if (this.state.user.status === "locked") {
      return (
        <div>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend("activate")}
              className="btn text-primary"
            >
              Activate
            </Button>
          </p>
        </div>
      );
    }

    if (this.state.user.status === "delete") {
      return (
        <p>
          <Button
            variant="link"
            onClick={(e) => this.handleSuspend("activate")}
            className="btn text-primary"
          >
            Activate
          </Button>
        </p>
      );
    }

    if (this.state.user.status === "suspend") {
      return (
        <div>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend("activate")}
              className="btn text-primary"
            >
              Activate
            </Button>
          </p>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend("delete")}
              className="btn text-primary"
            >
              Delete
            </Button>
          </p>
        </div>
      );
    }

    if (this.state.user.status === "pending") {
      return (
        <div>
          <p>
            <Button
              variant="link"
              onClick={(e) => this.handleSuspend(e)}
              className="btn text-primary"
            >
              Resend Invite
            </Button>
          </p>
        </div>
      );
    }
  }
  renderUser() {
    if (!this.state.user) {
      return (
        <section className="container">
          {this.state.errors.system ? (
            <Alert variant="danger">
              <p>System Error : Something went wrong.</p>
            </Alert>
          ) : (
            <Alert variant="info">
              <p>Fetching ...</p>
            </Alert>
          )}
        </section>
      );
    }

    let addModalClose = () => {
      this.setState({ modalIsOpen: false });
    };

    function formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    }

    return (
      <section className="container">
        <div className="mt-3 mb-3">
          <p className="h5 mb-3 text-muted text-capitalize">
            {this.state.user.first_name.substring(0, 1)} {"."}{" "}
            {this.state.user.last_name}
          </p>
        </div>

        <React.Fragment>{this.renderUserSummary()}</React.Fragment>

        <div className="row">
          <div className="col-lg-8">
            <div className="card mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  User Detail
                </h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="m-0 font-weight-bold text-muted">ID</h6>
                  </div>

                  <div className="col-lg-6">
                    <small>{this.state.user.app_user_id}</small>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="m-0 font-weight-bold text-muted">Status</h6>
                  </div>

                  <div className="col-lg-6 text-capitalize">
                    {this.state.user.status}
                  </div>
                </div>

                <hr></hr>

                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="m-0 font-weight-bold text-muted">Name</h6>
                  </div>

                  <div className="col-lg-6 text-capitalize blurred">
                    {this.state.user.first_name} {this.state.user.last_name}
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="m-0 font-weight-bold text-muted">Email</h6>
                  </div>

                  <div className="col-lg-6">{this.state.user.email}</div>
                </div>
                <hr></hr>

                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="m-0 font-weight-bold text-muted">Phone</h6>
                  </div>

                  <div className="col-lg-6 blurred">
                    {formatPhoneNumber(this.state.user.ph_mobile)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            {this.state.user.status === "pending" ? (
              <ResendUserModal
                showModal={this.state.modalIsOpen}
                user={this.state.user}
                hideModal={addModalClose}
              />
            ) : (
              <SuspendUserModal
                showModal={this.state.modalIsOpen}
                user={this.state.user}
                hideModal={addModalClose}
                action={this.state.targetAction}
              />
            )}
            <div className="card mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Action</h6>
              </div>

              <div className="card-body">{this.renderAction()}</div>
            </div>
          </div>
        </div>
        <div className="text-small"></div>
        {this.state.errors.system ? (
          <Alert className="mt-5" variant="danger">
            <Alert.Heading>Something went wrong</Alert.Heading>
            <p>{this.state.errors.system}</p>
          </Alert>
        ) : null}
      </section>
    );
  }

  render() {
    return (
      <section className="users container">
        <div className="mt-2 mb-2">
          <Link
            to={{
              pathname: PAGES.APP_USERS,
              state: { pageNum: this.state.currentPage },
            }}
          >
            &#x3c;<strong>Back to Users</strong>
          </Link>
        </div>
        <React.Fragment>{this.renderUser()}</React.Fragment>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, { post_app_user_list, user_kpi })(AppUserDetail),
);
