import "reflect-metadata";
import { Container } from "inversify";
import { ISpitKitService, SpitKitService } from "./SpitKitService";
import { IStore } from "./types/IStore";
import ValidationService from "./ValidationService";
import { IUSerService, UserService } from "./UserService";
import { CsvService, ICsvService } from "src/services/CsvService";
import {
  DownloadDataService,
  IDownloadDataService,
} from "src/services/DownloadDataService";

export type ServiceName =
  | "store"
  | "spitKitService"
  | "validationService"
  | "userService"
  | "csvService"
  | "downloadDataService";

export function injectServices(store: IStore): Container {
  const container = new Container();

  container.bind<IStore>("store").toConstantValue(store);
  container
    .bind<ISpitKitService>("spitKitService")
    .to(SpitKitService)
    .inSingletonScope();
  container
    .bind<ValidationService>("validationService")
    .to(ValidationService)
    .inSingletonScope();
  container
    .bind<IUSerService>("userService")
    .to(UserService)
    .inSingletonScope();
  container.bind<ICsvService>("csvService").to(CsvService).inSingletonScope();
  container
    .bind<IDownloadDataService>("downloadDataService")
    .to(DownloadDataService)
    .inSingletonScope();

  return container;
}
