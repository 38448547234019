import React from "react";
import { APP_NAME } from "../../constants/config";
import { PAGES } from "../../constants/pages";
import { withRouter } from "react-router-dom";
import { utils } from "src/utils/utils_general";

class Footer extends React.Component {
  year = new Date().getFullYear();

  redirect(url) {
    utils.redirectTo(url, true, this.props.history);
  }

  render() {
    return (
      <div className="footer p-3">
        <div className="col-12 text-center pt-5">
          &copy;&nbsp;{this.year}&nbsp;{APP_NAME}&nbsp;Portal&nbsp; |
          &nbsp;&nbsp;
          <a
            className="link"
            onClick={() => {
              this.redirect(PAGES.TERMS_AND_CONDITIONS);
            }}
          >
            Terms and Conditions
          </a>
          &nbsp; | &nbsp;&nbsp;
          <a
            className="link"
            onClick={() => {
              this.redirect(PAGES.PRIVACY_POLICY);
            }}
          >
            Privacy Policy
          </a>
          &nbsp;&nbsp;
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
