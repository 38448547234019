/**
 * @file components/Modals/publishSurveyModal.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { poll_status } from "../../actions/pollAction";
import { PAGES } from "../../constants/pages";
import { RESPONSE_CODE } from "../../constants/errors";

class DeletePollModal extends React.Component {
  constructor() {
    super();
    this.state = {
      buttonEnabled: true,
    };
  }

  deleteTheSurvey(nextStatus) {
    this.setState({ buttonEnabled: false });
    return this.props
      .poll_status(this.props.poll.poll_qn_id, nextStatus)
      .then((response) => {
        this.setState({ buttonEnabled: true });
        this.closeModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
            this.props.history.push(PAGES.LOGIN);
          }
        }
      });
  }

  closeModal() {
    this.props.hideModal();
  }

  render() {
    const surveyTitle = this.props.poll != null ? this.props.poll.title : "";
    const status = this.props.poll != null ? this.props.poll.status : "";

    let nextStatus = "";

    if (status === "active") nextStatus = "inactive";
    else nextStatus = "active";

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-uppercase"
          >
            {nextStatus} Question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to <code>{nextStatus}</code> the poll
          </p>

          <p>
            {" "}
            <b>{surveyTitle}</b>
          </p>
          <p className="pt-2">Do you wish to continue?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!this.state.buttonEnabled}
            variant="secondary"
            onClick={() => this.closeModal()}
          >
            Cancel
          </Button>
          <Button
            disabled={!this.state.buttonEnabled}
            variant="primary"
            onClick={() => this.deleteTheSurvey(nextStatus)}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    survey: ownProps.survey,
  };
};

export default withRouter(
  connect(mapStateToProps, { poll_status })(DeletePollModal),
);
