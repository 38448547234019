import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { Provider } from "react-redux";
import configureStore from "./store";
import { ServiceProvider } from "./services/ServicesContext";
import { injectServices } from "./services";

const store = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ServiceProvider container={injectServices(store)}>
      <App />
    </ServiceProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
