import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_poll } from "../api/api_poll";

export function import_poll() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_poll.import_poll(success, fail);
  };
}

export function getPolls(pageNum) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);

      dispatch({
        type: REDUX_ACTION,
        data: response.data.data,
      });

      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_poll.get_polls(success, fail, pageNum);
  };
}

export function poll_status(pollId, status) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER);
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEY_PUBLISH,
        data: response.data,
      });
      return response.data;
    };
    const fail = (error) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    return api_poll.poll_status(success, fail, pollId, status);
  };
}
//application/octet-stream//
