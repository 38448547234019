import React from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "src/components/global/spinner";

// These are the props directly used when returning the component: <GenericModal prop1={} prop2={} />
export type GenericModalComponentProps = {
  show?: boolean;
  size?: "sm" | "lg" | "xl";
  canProceed?: boolean;
  canCancel?: boolean;
  proceeding?: boolean;
  isAlternate?: boolean;
  title?: string | React.ReactChild;
  children?: string | React.ReactChild | React.ReactChild[];
  alternateAction?: React.ReactChild;
  onCancel?: () => void;
  onContinue?: () => void;
};

export type GenericModalProps = GenericModalComponentProps;

function GenericModal({
  show = false,
  size,
  title,
  children,
  canProceed = true,
  canCancel,
  proceeding = false,
  isAlternate = false,
  alternateAction,
  onCancel = () => {},
  onContinue = () => {},
}: GenericModalProps) {
  return (
    <Modal show={show} centered size={size} scrollable>
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {proceeding && <Spinner />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={canCancel === false || proceeding}
          variant="secondary"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        {!isAlternate && (
          <Button
            disabled={!canProceed || proceeding}
            variant="primary"
            onClick={() => onContinue()}
          >
            Continue
          </Button>
        )}
        {isAlternate && alternateAction}
      </Modal.Footer>
    </Modal>
  );
}

export default GenericModal;
