/**
 * @file components/login/consent.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Alert } from "react-bootstrap";
import { utils } from "../../utils/utils_general";
import PublishSurveyModal from "../modals/publish-survey-modal";
import UnPublishSurveyModal from "../modals/unpublish-survey-modal";
import { PAGES } from "../../constants/pages";
import {
  get_survey_list_get,
  survey_preview,
} from "../../actions/surveyAction";
import { download_survey_single_get } from "../../actions/surveyAction";
import {
  SURVEY_ERROR,
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
} from "../../constants/errors";
import GroupSurveyModal from "../modals/group-survey-modal";
import { survey_kpi } from "../../actions/reportAction";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPollH, faClock, faStar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class SurveyDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      survey: null,
      surveyId: null,
      status: null,
      published: false,
      currentPage: 1,
      isDownloading: false,
      groupModalIsOpen: false,
      groups: null,
      questions: "...",
      full_responses: "...",
      completed_responses: "...",
      incomplete_responses: "...",
      last_export: null,
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.survey &&
      !utils.is_obj_empty(this.props.location.state.survey)
    ) {
      this.setState({ surveyId: this.props.location.state.survey.survey_id });
      this.setState({
        last_export: this.props.location.state.survey.last_export,
      });

      this.setState(
        { currentPage: this.props.location.state.currentPage },
        () => this.getSurveys(),
      );
      this.getKPI(this.props.location.state.survey.survey_id);
    } else {
      this.props.history.push(PAGES.SURVEYS);
    }
  }

  handleDownloadSurvey(id) {
    this.setState({ isDownloading: true });
    return this.props
      .download_survey_single_get(id)
      .then((response) => {
        document.location.href = response.data;
        this.setState({ isDownloading: false });
      })
      .catch((error) => {
        this.setState({ isDownloading: false });
        if (error.response) {
          switch (error.response.status) {
            case RESPONSE_CODE["404_notfound"]:
              this.setState({
                errors: { system: SURVEY_ERROR.SURVEY_NOT_FOUND },
              });
              break;
            default:
              this.setState({ errors: { system: UNEXPECTED_ERROR } });
          }
        } else {
          this.setState({ errors: { system: UNEXPECTED_ERROR } });
        }
      });
  }

  updateSurveyStatus() {
    this.setState({ errors: "" });
    this.setState({ status: this.state.survey.status });
  }

  getSurveys() {
    return this.props
      .get_survey_list_get(this.state.currentPage)
      .then((response) => {
        this.setState({
          survey: response.surveys.find(
            (newSurvey) => newSurvey.survey_id === this.state.surveyId,
          ),
        });
        this.setState({ groups: response.groups });

        this.updateSurveyStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getKPI(survey_id) {
    return this.props
      .survey_kpi(survey_id)
      .then((response) => {
        this.setState({ questions: response.kpi.questions });
        this.setState({ full_responses: response.kpi.summary.full_responses });
        this.setState({
          completed_responses: response.kpi.summary.completed_responses,
        });
        this.setState({
          incomplete_responses: response.kpi.summary.incomplete_responses,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  highlightedSurvey() {
    if (this.state.groups) {
      const group = this.state.groups.find(
        (q) => q.survey_id === this.state.survey.survey_id,
      );

      if (!group) return;

      if (this.state.survey.status === "publish") {
        if (group.group_code_list === "important")
          return (
            <span className="text-warning">
              <FontAwesomeIcon icon={faStar} /> Highlighted Survey
            </span>
          );
        else
          return (
            <Button
              variant="mr-3 btn-info btn-sm"
              onClick={() => this.handleGroup(this.state.survey)}
            >
              Highlight
            </Button>
          );
      }
    }
  }

  findHighlighted() {
    if (this.state.groups) {
      const group = this.state.groups.find(
        (q) => q.group_code_list === "important",
      );

      if (group) return group.title;
      else return "None";
    }
  }

  formateDate(datestring) {
    const date = new Date(datestring);
    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
  }

  handleGroup(survey) {
    this.setState({ selectedSurveyItem: survey });
    this.setState({ groupModalIsOpen: !this.state.groupModalIsOpen });
  }

  handlePublish() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    this.getSurveys();
  }

  renderDownloadProcess() {
    return (
      <div className="downloading-survey-details">
        <div className="lds-dual-ring"></div> Downloading
      </div>
    );
  }

  renderSurveySummary() {
    if (!this.state.survey) {
      return null;
    }

    this.highlightedSurvey();

    return (
      <div className="row pt-4">
        <div className="col-xl-3 col-md-3 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Questions
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-muted">
                    {" "}
                    {this.state.questions}
                  </div>
                </div>
                <div className="col-auto  fa-3x  text-gray-300">
                  <FontAwesomeIcon icon={faPollH} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-3 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Responses
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-muted text-capitalize">
                    {this.state.full_responses}
                  </div>
                </div>
                <div className="col-auto  fa-3x  text-gray-300">
                  <FontAwesomeIcon icon={faPollH} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-3 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Completed Responses
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-muted">
                    {this.state.completed_responses}
                  </div>
                </div>
                <div className="col-auto  fa-3x  text-gray-300">
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-3 mb-3">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Incomplete Responses
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-muted">
                    {this.state.incomplete_responses}
                  </div>
                </div>
                <div className="col-auto  fa-3x  text-gray-300">
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  momentRender(sourceDate) {
    return moment(sourceDate, "YYYYMMDD").fromNow();
  }

  momentFormat(sourceDate) {
    if (sourceDate) return moment(sourceDate).format("MMMM D YYYY");
    return "NA";
  }

  renderSurveys() {
    const { survey, modalIsOpen, groupModalIsOpen, currentPage, errors } =
      this.state;

    if (!survey) {
      return null;
    }

    const {
      title,
      status,
      limesurvey_id,
      survey_id,
      last_export,
      description,
      created,
    } = survey;

    let addModalClose = () => {
      this.setState({
        modalIsOpen: false,
        groupModalIsOpen: false,
      });
      this.getSurveys();
    };

    return (
      <section className="surveys container">
        <div className="mt-3 mb-3">
          <h3 className="mb-3">{title}</h3>
          {status === "unpublish" ? (
            <PublishSurveyModal
              showModal={modalIsOpen}
              survey={survey}
              hideModal={addModalClose}
            />
          ) : (
            <UnPublishSurveyModal
              showModal={modalIsOpen}
              survey={survey}
              hideModal={addModalClose}
            />
          )}

          <GroupSurveyModal
            showModal={groupModalIsOpen}
            survey={survey}
            hideModal={addModalClose}
            highSurvey={this.findHighlighted()}
          ></GroupSurveyModal>

          <Button
            className="mr-3 btn-info btn-sm"
            onClick={() => this.handlePublish()}
          >
            {status === "publish" ? "Unpublish" : "Publish"}
          </Button>
          {this.highlightedSurvey()}
        </div>

        <React.Fragment>{this.renderSurveySummary()}</React.Fragment>
        <div>
          <p className="text-left text-gray-600">
            <small>
              {" "}
              Survey created on {this.momentFormat(title)}. Limesurvey ID{" "}
              {limesurvey_id}
            </small>
          </p>
        </div>
        <div className="text-small">
          <div className="row bg-light pt-2 pb-2 font-weight-bold">
            <div className="col-4">Survey ID</div>
            <div className="col-2">Status</div>
            <div className="col-2">Create Date</div>
            <div className="col-2">Last Export</div>
            <div className="col-2"> </div>
          </div>

          <div id={limesurvey_id} className="row border-bottom pb-3 pt-3 mb-3">
            <div className="col-4">
              <p>
                <small>{survey_id}</small>{" "}
              </p>
            </div>
            <div className={"col-2 text-capitalize " + status}>
              <span className="badge badge-primary">{status}ed</span>
            </div>
            <div className="col-2">
              <small>{this.momentFormat(created)}</small>
            </div>
            <div className="col-2">
              <small>{this.momentFormat(last_export)}</small>
            </div>
            <div className="col-2 p-0 pr-3 text-right">
              <Link
                to={{
                  pathname: PAGES.SURVEY_PREVIEW,
                  state: { survey: survey, currentPage: currentPage },
                }}
              >
                <u>Preview</u>
              </Link>
            </div>
          </div>
        </div>

        <h5>Description</h5>
        <div className="text-small text-wrap">
          <small>{description}</small>
        </div>
        {errors.system ? (
          <Alert className="mt-5" variant="danger">
            <Alert.Heading>Something went wrong</Alert.Heading>
            <p>{errors.system}</p>
          </Alert>
        ) : null}
      </section>
    );
  }

  render() {
    return (
      <section className="surveys container">
        <div className="mt-2 mb-2">
          <Link
            to={{
              pathname: PAGES.SURVEYS,
              state: {
                pageNum: this.state.currentPage,
                survey: this.state.survey,
              },
            }}
          >
            &#x3c;<strong>Back to Surveys</strong>
          </Link>
        </div>
        <React.Fragment>{this.renderSurveys()}</React.Fragment>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    location: ownProps.location,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    get_survey_list_get,
    download_survey_single_get,
    survey_kpi,
    survey_preview,
  })(SurveyDetail),
);
